import React, { useState, useEffect } from 'react';
import API from './../../api';

const TestRequest = () => {
  const test = async () => {
    const response = API.get('/admin/city/76472');

  }
  return (
    <div>
      <button onClick={test}>TEST</button>
    </div >
  );
};

export default TestRequest;
