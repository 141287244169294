// Header_User.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearUser, fetchUser } from './../../redux/userSlice';

import { debug } from './../../utils'
import UserSignInModal from './UserSignInModal';

function Header_User() {
  const navigate = useNavigate
  const { t } = useTranslation();// ['user', 'common']
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchUser()); // Appelle l'action fetchUser() pour récupérer l'utilisateur initialement
    const interval = window.setInterval(() => {
      dispatch(fetchUser());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const handleSignOut = () => {

    dispatch(clearUser());
  }
  return (
    <div className="">
      {!user.isSignedIn ? (
        <span className='pointer' onClick={e => setShowModal(true)}>{t('Signin')}</span>
      ) : (
        <>
          {user.type && <Link to={`/${user.type.toLowerCase()}/account`}>{t('MyAccount')}</Link>}
          <Link onClick={handleSignOut}>{t('Signout')}</Link>
        </>
      )}
      <UserSignInModal show={showModal} options={{ onSigned: '/customer/account' }} onClose={e => setShowModal(false)} onSuccess={e => setShowModal(false)} />
    </div>
  );
}
export default Header_User;