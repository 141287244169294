// Cart.js
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, debug } from './../../utils';
import { Link } from "react-router-dom";
// import OrderItem from './../orders/OrderItem';
import { useDispatch, useSelector } from 'react-redux';
import './cart.css';

// import ProductModal from '../products/ProductModal';
function Cart(catalog, onSelect = null) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart);

  return (
    <div className="cart">
      {debug(cart)}
    </div>
  )

}
export default Cart;
