// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#admin-content {
  padding: 40px 0 200px;
}

#admin-content .content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  margin-bottom: 40px;

}

#admin-content h1 {
  flex: 1 1;
  text-align: left;
  padding: 0 15px 7px 0;
  margin-bottom: 0;
  font-size: 1.6rem;
}


#admin-content .content-title:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 69px;
  height: 3px;
  content: ' ';
  background-color: #ffde00;
}


#admin-content .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

#admin-content .section-title h2 {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/layouts/adminLayout.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;;EAElB,mBAAmB;;AAErB;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;AACnB;;;AAGA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;;;AAGA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,UAAU;AACZ","sourcesContent":["#admin-content {\r\n  padding: 40px 0 200px;\r\n}\r\n\r\n#admin-content .content-title {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  position: relative;\r\n\r\n  margin-bottom: 40px;\r\n\r\n}\r\n\r\n#admin-content h1 {\r\n  flex: 1;\r\n  text-align: left;\r\n  padding: 0 15px 7px 0;\r\n  margin-bottom: 0;\r\n  font-size: 1.6rem;\r\n}\r\n\r\n\r\n#admin-content .content-title:before {\r\n  position: absolute;\r\n  top: 100%;\r\n  left: 0;\r\n  width: 69px;\r\n  height: 3px;\r\n  content: ' ';\r\n  background-color: #ffde00;\r\n}\r\n\r\n\r\n#admin-content .section-title {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  position: relative;\r\n}\r\n\r\n#admin-content .section-title h2 {\r\n  font-size: 1rem;\r\n  margin: 0;\r\n  padding: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
