// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-counter {
  background-color: #eee;
}


.badge-counter.badge-sm {}`, "",{"version":3,"sources":["webpack://./src/components/helpers/BadgeCounter/badge-counter.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;;AAGA,yBAAyB","sourcesContent":[".badge-counter {\r\n  background-color: #eee;\r\n}\r\n\r\n\r\n.badge-counter.badge-sm {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
