// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 575px) {

  #order-signin .buttons-section {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/orders/order-confirm.css"],"names":[],"mappings":"AAAA;;EAEE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["@media screen and (max-width: 575px) {\r\n\r\n  #order-signin .buttons-section {\r\n    text-align: center;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
