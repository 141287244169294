
import React, { useEffect, useState } from 'react';

import API from '../../../api';
import { debug } from '../../../utils';
const DialCodes = ({ value = '', onSelect, disabled = false }) => {
  const [dialCodes, setDialCodes] = useState([]);
  const [selected, setSelected] = useState('');
  useEffect(() => {
    const get_dial_codes = async () => {
      try {
        const response = await API.get('/config/dial_codes');
        setDialCodes(response.data)
      } catch (e) {
      }
    }
    get_dial_codes();
  }, []);
  useEffect(() => {
    if (!value || value == '') {
      onSelect(dialCodes[0]?.dial_code);
    }
  }, [dialCodes]);
  return (
    <>
      <select
        className='form-control flex-grow-0'
        disabled={disabled}
        style={{ width: '80px' }}
        value={value}
        onChange={e => onSelect(e.target.value)}>
        {dialCodes.map(dialCode => (
          <option key={dialCode.code} value={dialCode.dial_code}>{dialCode.dial_code}</option>
        ))

        }
      </select>

    </>
  )
}
export default DialCodes;
