// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AdminPickupModalOccurrences .switcher .handler {
  background-color: white;
}

#AdminPickupModalOccurrences .switcher.on {
  background-color: rgba(var(--bs-danger-rgb));
}

#AdminPickupModalOccurrences .switcher.off {
  background-color: rgba(var(--bs-secondary-rgb));
}

#AdminPickupModalOccurrences .inactive {
  opacity: 0.4;
}

.admin-pickup-modal .modal-body.occurrences .tab-contents {
  padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/pickups/admin/incAdminPickup/admin-pickups-occurrences.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","sourcesContent":["#AdminPickupModalOccurrences .switcher .handler {\r\n  background-color: white;\r\n}\r\n\r\n#AdminPickupModalOccurrences .switcher.on {\r\n  background-color: rgba(var(--bs-danger-rgb));\r\n}\r\n\r\n#AdminPickupModalOccurrences .switcher.off {\r\n  background-color: rgba(var(--bs-secondary-rgb));\r\n}\r\n\r\n#AdminPickupModalOccurrences .inactive {\r\n  opacity: 0.4;\r\n}\r\n\r\n.admin-pickup-modal .modal-body.occurrences .tab-contents {\r\n  padding-top: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
