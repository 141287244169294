// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-form-container {
  max-width: 400px;
  margin: 0 auto;
}

.user-form-container .form-group-submit {
  padding-top: 25px;

}


.password-features {

  list-style: none;
  padding: 0;
  margin: 0 0 25px 0;
}

.password-features li {
  list-style: none;
  padding: 0px 0;
  line-height: 1.6;


}


.password-features svg {
  margin-right: 5px;
  font-size: 0.8rem;
  height: 15px;
}

.password-features .ok {
  color: green
}

.password-features .ko {
  color: red
}


/* Pour Webkit (Safari, Chrome, et Opera) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
}

/* Pour Mozilla Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/user/user-form.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,iBAAiB;;AAEnB;;;AAGA;;EAEE,gBAAgB;EAChB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;;;AAGlB;;;AAGA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;;AAGA,2CAA2C;AAC3C;;EAEE,wBAAwB;EACxB,SAAS;EACT,kBAAkB;AACpB;;AAEA,yBAAyB;AACzB;EACE,0BAA0B;EAC1B,kBAAkB;AACpB","sourcesContent":[".user-form-container {\r\n  max-width: 400px;\r\n  margin: 0 auto;\r\n}\r\n\r\n.user-form-container .form-group-submit {\r\n  padding-top: 25px;\r\n\r\n}\r\n\r\n\r\n.password-features {\r\n\r\n  list-style: none;\r\n  padding: 0;\r\n  margin: 0 0 25px 0;\r\n}\r\n\r\n.password-features li {\r\n  list-style: none;\r\n  padding: 0px 0;\r\n  line-height: 1.6;\r\n\r\n\r\n}\r\n\r\n\r\n.password-features svg {\r\n  margin-right: 5px;\r\n  font-size: 0.8rem;\r\n  height: 15px;\r\n}\r\n\r\n.password-features .ok {\r\n  color: green\r\n}\r\n\r\n.password-features .ko {\r\n  color: red\r\n}\r\n\r\n\r\n/* Pour Webkit (Safari, Chrome, et Opera) */\r\ninput[type=\"number\"]::-webkit-inner-spin-button,\r\ninput[type=\"number\"]::-webkit-outer-spin-button {\r\n  -webkit-appearance: none;\r\n  margin: 0;\r\n  text-align: center;\r\n}\r\n\r\n/* Pour Mozilla Firefox */\r\ninput[type=\"number\"] {\r\n  -moz-appearance: textfield;\r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
