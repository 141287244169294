// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#default-layout {}

body.standalone #default-layout .content {
  padding: 120px 0 200px;
}


body.embeded #default-layout .content {
  padding-top: 0px !important;

  padding-bottom: 50px !important;

}`, "",{"version":3,"sources":["webpack://./src/layouts/defaultLayout.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,sBAAsB;AACxB;;;AAGA;EACE,2BAA2B;;EAE3B,+BAA+B;;AAEjC","sourcesContent":["#default-layout {}\r\n\r\nbody.standalone #default-layout .content {\r\n  padding: 120px 0 200px;\r\n}\r\n\r\n\r\nbody.embeded #default-layout .content {\r\n  padding-top: 0px !important;\r\n\r\n  padding-bottom: 50px !important;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
