// Header.js
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './header.css';
import { isDev } from './../../utils';
import './backoffice-header.css';
import TestRequest from './../tests/TestRequest';
import API from './../../api';
const AdminHeader = React.memo(() => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const backoffice_url = window.sessionStorage.getItem('backoffice_url');
  return (
    <header id="backoffice-header">
      <div id="header-main">
        <div className='row'>
          <div className='col' id='header-main-left'>
            <Link className='logo' to="/appAdmin">
              <strong>Backoffice</strong>
              {/* <img src='/img/logo.png' alt='amd jaeger' /> */}
            </Link>
          </div>
          <div className='col col-8 col-center' id=''>
            <Link to="/appAdmin/planning">Planning</Link>
            <Link to="/appAdmin/orders">Commandes</Link>
            <Link to="/appAdmin/customers">Clients</Link>
            <Link to="/appAdmin/pickups">Tournées</Link>
            <Link to="/appAdmin/cities">Communes</Link>
            <Link to="/appAdmin/catalog">Tarifs</Link>
            <Link to="/appAdmin/partners">Entreprises</Link>
            <Link to="/appAdmin/discountCodes">Code Promo</Link>
            <Link to="/appAdmin/workshop/connect">Atelier</Link>
          </div>
          <div className='col' id='header-main-right'>
            {(isDev || true) &&
              <span>
                {user?.firstName}
                {/* <TestRequest /> */}
              </span>
            }
            {false &&
              <a href={backoffice_url}>RETOUR BACKOFFICE</a>
            }
          </div>
        </div>
      </div>
    </header>
  );
});

export default React.memo(AdminHeader);
