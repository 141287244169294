// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AdminOrderModalEditPayment>div {}

#AdminOrderModalEditPayment *:read-only {
  background-color: #fcfcfc;
}

#AdminOrderModalEditPayment *:read-only:focus {
  border-color: #dee2e6 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/admin/admin-order-modal-edit-payment.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;EACE,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":["#AdminOrderModalEditPayment>div {}\r\n\r\n#AdminOrderModalEditPayment *:read-only {\r\n  background-color: #fcfcfc;\r\n}\r\n\r\n#AdminOrderModalEditPayment *:read-only:focus {\r\n  border-color: #dee2e6 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
