// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-pickup-modal h6 {
  font-size: 0.95rem;
  margin: 0;
  padding: 0;
}



#AdminPickupModalCities .table-wrapper {
  height: 50vh;
  overflow-y: scroll;
}



#AdminPickupModalCities .row,
#AdminPickupModalCities .row>div {
  border: 0px salmon solid;
}

#AdminPickupModalCities td {
  vertical-align: middle;
}

#AdminPickupModalCities td.checkbox {
  width: 25px;
  text-align: center;
  padding-top: 5px;
}

#AdminPickupModalCities .form-control {
  padding: 0.05rem 0.5rem;
  font-size: 0.7rem;
}

#AdminPickupModalOccurrences .occurrence {}

#AdminPickupModalOccurrences .occurrence-header {
  border-bottom: 1px solid white;
}

#AdminPickupModalOccurrences .badge {
  font-size: 0.65rem !important;
  font-weight: normal !important;
  padding: 0.5em 0.2em !;

}


#AdminPickupModalOccurrences .occurrence-body {}`, "",{"version":3,"sources":["webpack://./src/components/pickups/admin/admin-pickup-modal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;;;AAIA;EACE,YAAY;EACZ,kBAAkB;AACpB;;;;AAIA;;EAEE,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA,0CAA0C;;AAE1C;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;EAC7B,8BAA8B;EAC9B,sBAAsB;;AAExB;;;AAGA,+CAA+C","sourcesContent":[".admin-pickup-modal h6 {\r\n  font-size: 0.95rem;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n\r\n\r\n#AdminPickupModalCities .table-wrapper {\r\n  height: 50vh;\r\n  overflow-y: scroll;\r\n}\r\n\r\n\r\n\r\n#AdminPickupModalCities .row,\r\n#AdminPickupModalCities .row>div {\r\n  border: 0px salmon solid;\r\n}\r\n\r\n#AdminPickupModalCities td {\r\n  vertical-align: middle;\r\n}\r\n\r\n#AdminPickupModalCities td.checkbox {\r\n  width: 25px;\r\n  text-align: center;\r\n  padding-top: 5px;\r\n}\r\n\r\n#AdminPickupModalCities .form-control {\r\n  padding: 0.05rem 0.5rem;\r\n  font-size: 0.7rem;\r\n}\r\n\r\n#AdminPickupModalOccurrences .occurrence {}\r\n\r\n#AdminPickupModalOccurrences .occurrence-header {\r\n  border-bottom: 1px solid white;\r\n}\r\n\r\n#AdminPickupModalOccurrences .badge {\r\n  font-size: 0.65rem !important;\r\n  font-weight: normal !important;\r\n  padding: 0.5em 0.2em !;\r\n\r\n}\r\n\r\n\r\n#AdminPickupModalOccurrences .occurrence-body {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
