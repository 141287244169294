import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header_User from './../user/Header_User';
import Header_Cart from './../cart/Header_Cart';
import { isDev } from './../../utils';
import { Link } from 'react-router-dom';
import './header.css';
import './frontoffice-header.css';
import API from './../../api';

const Header = React.memo(() => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  if (window !== window.parent) return null;
  return (
    <header className="App-header" id="header">
      <div id="header-main">
        <div className='row'>
          <div className='col' id='header-main-left'>
            <Link className='logo' to="/">
              <img src='/img/logo.png' alt='' />
            </Link>
          </div>
          <div className='col' id='header-main-right'>
            <Header_User />
            <Header_Cart />
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
