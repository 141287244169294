// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

:root {
  --collecte: #046FB5;
  --restitution: #D9F371;
  --paid: #198754;
  --unpaid: #dc3545;
  --partial: #ffc107;
  --active: #dc3545;
  --pending: #dc3545;
  --account: rgba(111, 165, 226, 0.8);
  /* commandes des clients en compte : ordPaymentStatus =account */
}

.collecte-bg {
  background-color: var(--collecte);
  color: white;
}

.restitution-bg {
  background-color: var(--restitution);
  color: #666;
}

.text-red {
  color: red !important;
}


.order-pending {
  color: var(--partial) !important;
}

.order-canceled {
  color: #000 !important;
}

.order-handled {
  color: #0a58ca !important;
}

.order-completed {
  color: var(--paid) !important;
}`, "",{"version":3,"sources":["webpack://./src/css/colors.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,8BAA8B;EAC9B,0BAA0B;EAC1B,0BAA0B;EAC1B,oCAAoC;EACpC,uCAAuC;EACvC,2BAA2B;EAC3B,2BAA2B;EAC3B,qCAAqC;EACrC,4DAA4D;EAC5D,6BAA6B;EAC7B,6BAA6B;EAC7B,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;EACnC,gEAAgE;AAClE;;AAEA;EACE,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;;AAGA;EACE,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".btn-primary {\r\n  --bs-btn-color: #fff;\r\n  --bs-btn-bg: #0d6efd;\r\n  --bs-btn-border-color: #0d6efd;\r\n  --bs-btn-hover-color: #fff;\r\n  --bs-btn-hover-bg: #0b5ed7;\r\n  --bs-btn-hover-border-color: #0a58ca;\r\n  --bs-btn-focus-shadow-rgb: 49, 132, 253;\r\n  --bs-btn-active-color: #fff;\r\n  --bs-btn-active-bg: #0a58ca;\r\n  --bs-btn-active-border-color: #0a53be;\r\n  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);\r\n  --bs-btn-disabled-color: #fff;\r\n  --bs-btn-disabled-bg: #0d6efd;\r\n  --bs-btn-disabled-border-color: #0d6efd;\r\n}\r\n\r\n:root {\r\n  --collecte: #046FB5;\r\n  --restitution: #D9F371;\r\n  --paid: #198754;\r\n  --unpaid: #dc3545;\r\n  --partial: #ffc107;\r\n  --active: #dc3545;\r\n  --pending: #dc3545;\r\n  --account: rgba(111, 165, 226, 0.8);\r\n  /* commandes des clients en compte : ordPaymentStatus =account */\r\n}\r\n\r\n.collecte-bg {\r\n  background-color: var(--collecte);\r\n  color: white;\r\n}\r\n\r\n.restitution-bg {\r\n  background-color: var(--restitution);\r\n  color: #666;\r\n}\r\n\r\n.text-red {\r\n  color: red !important;\r\n}\r\n\r\n\r\n.order-pending {\r\n  color: var(--partial) !important;\r\n}\r\n\r\n.order-canceled {\r\n  color: #000 !important;\r\n}\r\n\r\n.order-handled {\r\n  color: #0a58ca !important;\r\n}\r\n\r\n.order-completed {\r\n  color: var(--paid) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
