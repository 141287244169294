// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#DiscountCodesList {}

#DiscountCodesList thead th {
  vertical-align: top !important;
  text-align: center !important;
}`, "",{"version":3,"sources":["webpack://./src/components/discountCodes/admin/discount-codes-admin.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;EACE,8BAA8B;EAC9B,6BAA6B;AAC/B","sourcesContent":["#DiscountCodesList {}\r\n\r\n#DiscountCodesList thead th {\r\n  vertical-align: top !important;\r\n  text-align: center !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
