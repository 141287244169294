import React, { useState, useEffect } from 'react';
import { download, formatPrice, formatDate, debug } from './../../../utils';
import './admin-pickup-occurrence.css'
import Switcher from '../../helpers/Switcher/Switcher';
import { MdOpenInNew, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdManageSearch } from "react-icons/md";
import { BsArrowLeft, BsArrowRight, BsPause, BsPrinter } from "react-icons/bs";
import AdminOrderDetail from '../../orders/admin/AdminOrderDetails';
import { Modal } from 'react-bootstrap';
import './../../../css/forms.css';
import './../../../css/colors.css';
import './admin-pickup-occurrence.css';
import { cloneDeep } from 'lodash';
const AdminPickupOccurrence = ({ occurrence, paidOrderOnly = true }) => {
  const [occurrenceCopy, setOccurrenceCopy] = useState(null)
  const [paidOrderOnlyCopy, setPaidOrderOnlyCopy] = useState(false)
  const [flows, setFlows] = useState(['collecte', 'restitution']);
  const [cities, setCities] = useState(null)
  const [editedOrder, setEditedOrder] = useState(null)
  useEffect(() => {
    setOccurrenceCopy(occurrence);
  }, [occurrence]);
  useEffect(() => {
    if (occurrenceCopy) {
      const uniqueCities = {};
      occurrenceCopy.cities.forEach(function (city) {
        uniqueCities[city.citId] = city;
        uniqueCities[city.citId].orders = []
      });
      occurrenceCopy?.orders.forEach(order => {
        uniqueCities[order.items[0].oiCityId].orders.push(order)
      });
      const sortedCities = Array.from(Object.values(uniqueCities)).sort((cityA, cityB) => cityA.citId.localeCompare(cityB.citId));
      setCities(sortedCities);
    }
  }, [occurrenceCopy, paidOrderOnlyCopy, flows])
  // 
  useEffect(() => { }, [paidOrderOnlyCopy]);
  //
  const handleToggleFlow = (event) => {
    const flow = event.currentTarget.dataset.flow;
    const idx = flows.indexOf(flow);
    if (idx > -1) {
      const flowsCopy = [...flows]; // Copie flows
      flowsCopy.splice(idx, 1); // Supprime l'élément à l'index idx
      setFlows(flowsCopy);
    } else {
      setFlows([...flows, flow]);
    }
  }
  const handlePrintPickupOccurrence = (occurrence) => {
    download(`/admin/occurrence/${occurrence.poId}/pdf`, () => { })
  }
  const handleOrderUpdated = (order) => {
    console.clear();
    const index = occurrenceCopy.orders.findIndex(o => o.ordId == order.ordId);

    const orderCopy = {
      ...order,
      customer: occurrenceCopy.orders[index].customer
    };
    console.log(orderCopy);

    orderCopy.items = order.items.filter(oi => oi.oiPickupOccurrenceId == occurrenceCopy.poId);
    const ordersCopy = cloneDeep(occurrenceCopy.orders);
    ordersCopy[index] = { ...orderCopy };
    console.log(ordersCopy);
    console.log('95C')
    console.log(ordersCopy[0].ordTotalIT);
    setOccurrenceCopy({ ...occurrenceCopy, orders: [...ordersCopy] });
    setEditedOrder(null);
  }
  return (
    <div id="AdminPickupOccurrence">
      <div className='toolbar'>
        <div className='row justify-content-between  py-2'>
          <div className='col'></div>
          <div className='col text-center small '>
            <label className='mb-1'>Flux : </label><br />
            <label className='pointer me-3 px-2 flow-label collecte-bg' data-flow="collecte" onClick={handleToggleFlow}>
              {flows.indexOf('collecte') === -1 ?
                <MdOutlineCheckBoxOutlineBlank className="me-1" />
                :
                <MdOutlineCheckBox className="me-1" />
              }
              Collectes
            </label>
            <label className='pointer mb-0 px-2  flow-label restitution-bg' data-flow="restitution" onClick={handleToggleFlow}>
              {flows.indexOf('restitution') === -1 ?
                <MdOutlineCheckBoxOutlineBlank className="me-1" />
                :
                <MdOutlineCheckBox className="me-1" />
              }
              Restitution
            </label>
          </div>
          <div className='col'>
            <div className='text-center'>
              <label className='small mb-1'>Commandes réglées uniquement : </label><br />
              <label className='small d-flex align-items-center justify-content-center'>
                Non &nbsp;
                <Switcher onClassName='switcher-on' onChange={e => { setPaidOrderOnlyCopy(!paidOrderOnlyCopy) }} value={paidOrderOnlyCopy} />
                &nbsp; Oui
              </label>
            </div>
          </div>
          <div className='col col-1 text-end pe-4 pt-2'>
            <a onClick={e => handlePrintPickupOccurrence(occurrence)} title="Fiche tournée"><BsPrinter size={30} /></a>
          </div>
        </div>
      </div>
      {
        (occurrenceCopy && occurrenceCopy?.poId) &&
        <div style={{ height: '450px', overflowY: 'scroll' }} className='pe-3'>
          <table className='table table-sm table-bordered'>
            {
              cities?.map((city, idx) => (
                city.orders.length > 0 &&
                <React.Fragment key={city.citId}>
                  <thead style={{ marginTop: '10px' }}>
                    <tr>
                      <td style={{ width: '7px' }}></td>
                      <td colSpan={2}><strong>{city.citName}</strong></td>
                      <td className='text-center' >{city.pocSlotStart}-{city.pocSlotEnd}</td>
                      <td className='text-center' style={{ width: '90px' }}>A régler</td>
                      <td className='text-center' style={{ width: '90px' }}>Réglé</td>
                      <td className='text-center' style={{ width: '90px' }}>Solde</td>
                      <td style={{ width: '40px' }}></td>
                      <td className='text-center' style={{ width: '7px' }}></td>
                    </tr>
                  </thead>
                  {city.orders.map(order => (
                    <React.Fragment key={order.ordId}>
                      {(paidOrderOnlyCopy == false || order.ordPaymentStatus === 'paid') &&
                        <tbody>
                          <tr className={`${order.items[0].oiFlow} ${order.items[0].oiStatus}`} >
                            <td style={{ background: `var(--${order.items[0].oiFlow})` }}></td>
                            <td className='text-center' style={{ width: '120px' }}>
                              {order.ordNumber}
                            </td>
                            <td>
                              {order.customer.custName} {order.customer.custFirstname}<br />
                              {order.customer?.user?.userPhone}
                            </td>
                            <td>
                            </td>
                            <td className='text-center'>
                              {formatPrice(order.ordToBePaid)}
                            </td>
                            <td className='text-center'>
                              {formatPrice(order.ordPaid)}
                            </td>
                            <td className='text-center'>
                              {formatPrice(order.ordBalance)}
                            </td>
                            <td className='text-center' style={{ width: '50px' }}>
                              <span className='pointer' onClick={e => setEditedOrder(order)} >
                                <MdOpenInNew size={16} />
                              </span>
                            </td>
                            <td style={{ background: order.ordPaymentStatus == '' ? `var(--unpaid` : `var(--${order.ordPaymentStatus})` }}></td>
                          </tr>
                        </tbody>
                      }
                    </React.Fragment>))}
                </React.Fragment>
              ))
            }
          </table>
        </div>
      }
      {/* Modal */}
      {editedOrder &&
        <Modal show={editedOrder} onHide={e => setEditedOrder(null)} size="xl">
          <Modal.Header closeButton>
            <strong>{editedOrder.ordNumber}</strong>
          </Modal.Header>
          <Modal.Body >
            <div style={{ paddingTop: '40px' }}>
              <AdminOrderDetail orderId={editedOrder.ordId} onUpdated={handleOrderUpdated} />
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>}
    </div >
  )
}
export default AdminPickupOccurrence;