import React, { useState, useEffect } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { MdOpenInNew, MdRefresh } from 'react-icons/md';
import EditDiscountCodeModal from './EditDiscountCodeModal';
import API from './../../../api';
import { debug, formatDate } from '../../../utils';
import './discount-codes-admin.css';

const DiscountCodesList = () => {
  const { t } = useTranslation();
  const [discountCodes, setDiscountCodes] = useState([]);
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [filters, setFilters] = useState({
    dcCode: '',
    dcDescription: '',
    dcStatus: '',
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const get_discount_codes = async () => {
    try {
      const response = await API.get('/admin/discountCodes');
      const sortedData = response.data.sort((a, b) => a.dcCode.localeCompare(b.dcCode));
      setDiscountCodes(sortedData);
      setFilteredCodes(sortedData);
    } catch (e) {
      console.log(e);
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    get_discount_codes();
  }, []);

  useEffect(() => {
    // Filtre les codes en fonction des filtres actuels
    const filtered = discountCodes.filter((code) => {
      return (
        code.dcCode.toLowerCase().includes(filters.dcCode.toLowerCase()) &&
        code.dcDescription.toLowerCase().includes(filters.dcDescription.toLowerCase()) &&
        (filters.dcStatus === '' || code.dcStatus === filters.dcStatus)
      );
    });
    setFilteredCodes(filtered);
  }, [filters, discountCodes]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleEditClick = (code) => {
    setSelectedCode(code);
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setSelectedCode(null);
  };

  const handleSaved = (discountCode) => {
    try {
      const updatedCodes = [...discountCodes];
      const existingIndex = updatedCodes.findIndex((code) => code.dcId === discountCode.dcId);

      if (discountCode.dcStatus === 'deleted') {
        // Supprime le code s'il a un dcStatus 'deleted'
        if (existingIndex > -1) {
          updatedCodes.splice(existingIndex, 1);
        }
      } else {
        if (existingIndex > -1) {
          // Mise à jour du code existant
          updatedCodes[existingIndex] = discountCode;
        } else {
          // Ajout du nouveau code promo
          updatedCodes.push(discountCode);
        }
      }

      const sortedData = updatedCodes.sort((a, b) => a.dcCode.localeCompare(b.dcCode));
      setDiscountCodes(sortedData);
      setFilteredCodes(sortedData);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCreateClick = () => {
    setSelectedCode({
      dcId: '',
      dcCode: '',
      dcDescription: '',
      dcScope: 'category',
      dcType: 'percentage',
      dcAmount: '',
      dcStatus: 'active',
    });
    setShowEditModal(true);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-4">
        <h1>Codes promo</h1>
        <div className="flex-grow-0 text-end">
          <Button
            variant="outline-warning"
            onClick={handleCreateClick}
            className="btn"
          >
            Créer un Code Promo
          </Button>
          <Button
            variant="outline-warning"
            onClick={get_discount_codes}
            className="btn ms-2"
          >
            <MdRefresh size={20} />
          </Button>
        </div>
      </div>
      <div className="table-responsive">
        {loaded && (
          discountCodes.length === 0 ? (
            <p className='py-5 text-center'>Aucun code promo trouvé.</p>
          ) : (
            <Table striped bordered hover className="table-sm">
              <thead>
                <tr>
                  <th className='text-center'>Code Promo</th>
                  <th className='text-center'>Description</th>
                  <th className='text-center'>Type</th>
                  <th className='text-center'>Montant</th>
                  <th className='text-center'>A partir du</th>
                  <th className='text-center'>Jusqu'au</th>
                  <th className='text-center'>Statut</th>
                  <th className='text-center'></th>
                </tr>
                <tr>
                  <th>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="dcCode"
                      value={filters.dcCode}
                      onChange={handleFilterChange}
                      placeholder="Filtrer..."
                    />
                  </th>
                  <th>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="dcDescription"
                      value={filters.dcDescription}
                      onChange={handleFilterChange}
                      placeholder="Filtrer..."
                    />
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <Form.Select
                      size="sm"
                      name="dcStatus"
                      value={filters.dcStatus}
                      onChange={handleFilterChange}
                    >
                      <option value="">Tous</option>
                      <option value="active">{t(`discount_code_active`)}</option>
                      <option value="expired">{t(`discount_code_expired`)}</option>
                      <option value="disabled">{t(`discount_code_disabled`)}</option>
                    </Form.Select>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredCodes.map((code) => (
                  <tr key={code.dcId}>
                    <td className='text-center'>{code.dcCode}</td>
                    <td>{code.dcDescription}</td>
                    <td className='text-center'>{t(`discount_code_${code.dcType}`)}</td>
                    <td className='text-center'>{code.dcAmount}</td>
                    <td className='text-center'>{code.dcValidFrom ? formatDate(code.dcValidFrom) : 'Non défini'}</td>
                    <td className='text-center'>{code.dcValidUntil ? formatDate(code.dcValidUntil) : 'Non défini'}</td>
                    <td className='text-center'>{t(`discount_code_${code.dcStatus}`)}</td>
                    <td className='text-center'>
                      <MdOpenInNew className="pointer" size={16} onClick={() => handleEditClick(code)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ))}
        {selectedCode && (
          <EditDiscountCodeModal
            show={showEditModal}
            handleClose={handleCloseModal}
            onSaved={handleSaved}
            selectedCode={selectedCode}
          />
        )}
      </div>
    </div>
  );
};

export default DiscountCodesList;
