// https://fullcalendar.io/docs#toc
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector, useDispatch } from 'react-redux';
import API from '../../../api';
import { debug, formatDate, formatPrice } from '../../../utils';
import AdminPickupOccurrence from './AdminPickupOccurrence'
import FullCalendar from '@fullcalendar/react'
import AdminOrderDetail from './../../orders/admin/AdminOrderDetails';
import AdminCustomer from './../../customers/admin/AdminCustomer';
import interactionPlugin from '@fullcalendar/interaction';
import { Link, useNavigate } from 'react-router-dom';

import { AiOutlineDownload } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';
import moment from 'moment';
import { download } from '../../../utils';
import dayGridPlugin from '@fullcalendar/daygrid';
import Modal from 'react-bootstrap/Modal';
import allLocales from '@fullcalendar/core/locales-all'
import Switcher from '../../helpers/Switcher/Switcher';
import './../../../css/calendar.css';
import './admin-pickups-calendar-v2.css';
import _ from 'lodash';

/* ----------------------
AdminPickupCalendarV2
---------------------- */
function AdminPickupCalendarV2() {
  const navigate = useNavigate();
  const config = useSelector((state) => state.config);
  const weekendsVisible = false;
  const [paidOrderOnly, setPaidOrderOnly] = useState(false);
  const [occurrences, setOccurrences] = useState([]);
  const [events, setEvents] = useState([]);
  const [day, setDay] = useState(moment().format('YYYY-MM-DD')); // 
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState('');
  const [stats, setStats] = useState({});
  const [dayOccurrences, setDayOccurences] = useState([]);
  const [editedOrder, setEditedOrder] = useState(null);
  const [editedOccurrence, setEditedOccurrence] = useState(null);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const today = moment().format('YYYY-MM-DD');
  useEffect(() => {
  }, []);
  useEffect(() => {
  }, [paidOrderOnly]);
  /* ----------------------
  handleDatesSet
  ---------------------- */
  const handleDatesSet = async (args) => {
    const query = { 'poDate:gte': args.startStr.substr(0, 10), 'poDate:lte': args.endStr.substr(0, 10) };
    const qs = '?include=pickups,orders,order_items&query=' + JSON.stringify(query);
    const response = await API.get('/admin/occurrences' + qs);
    setOccurrences(response.data);
  }
  useEffect(() => {
    const str = formatDate(day, 'textfull');
    setDate(str.charAt(0).toUpperCase() + str.slice(1))
    getDayOccurrences(day);
  }, [day]);
  useEffect(() => {
    const eventsObj = {};
    occurrences.forEach(po => {
      if (typeof eventsObj[po.poDate]) {
        eventsObj[po.poDate] = {
          id: po.poDate,
          title: '',
          start: po.poDate,
          end: po.poDate,
          allDay: true,
          collectes: 0,
          restitutions: 0,
        }
      }
      eventsObj[po.poDate].collectes += po.order_items.filter(oi => oi.oiFlow === 'collecte').length;
      eventsObj[po.poDate].restitutions += po.order_items.filter(oi => oi.oiFlow === 'restitution').length;
    });
    setEvents(Object.values(eventsObj));
  }, [occurrences]);
  useEffect(() => {
    const statsCopy = {};
    dayOccurrences.forEach(po => {
      statsCopy[po.poId] = {
        collectes: po.order_items.filter(oi => oi.oiFlow === 'collecte'),
        restitutions: po.order_items.filter(oi => oi.oiFlow === 'restitution')
      }
    });
    setStats(statsCopy);
  }, [dayOccurrences]);
  /* ----------------------
  renderEventContent
  ---------------------- */
  const renderEventContent = (eventInfo) => {
    const event = events.find(ev => ev.id === eventInfo.event.id);
    return (
      <div className='text-center'>
        {event && event.collectes > 0 || event.restitutions > 0 ? <span className='red-circle'>{event.collectes + event.restitutions}</span> : <></>}
      </div>
    );
  }
  /* ----------------------
  handleCalendarClick
  ---------------------- */
  const handleCalendarClick = (event) => {
  }
  /* ----------------------
  handleEventClick
  ---------------------- */
  const handleEventClick = async (clickInfo) => {
    setDay(clickInfo.event.id);
    return;
  }
  /* ----------------------
  getDayOccurrences
  ---------------------- */
  const getDayOccurrences = async (day) => {
    const queryObj = { 'poDate:eq': day };
    let qs = '?query=' + JSON.stringify(queryObj);
    qs += '&include=pickups,order_items,customers';
    try {
      const response = await API.get('/admin/occurrences' + qs);
      const data = [...response.data.filter(it => it.order_items.length > 0)];
      setDayOccurences(data);
    } catch (e) {
    }
  }
  /* ----------------------
  handleDateSelect
  ---------------------- */
  const handleDateSelect = (selectInfo) => {
  };
  /* ----------------------
  handleCustomerUpdated
  ---------------------- */
  const handleCustomerUpdated = (customer) => {
    const occurrences_copy = _.clone(dayOccurrences);
    occurrences_copy.forEach((occurrence, idxo) => {
      if (occurrence.order_items.length > 0) {
        occurrence.order_items.forEach((oi, idxi) => {
          if (oi.customer.custId === customer.custId) {
            const newCustomer = { ...oi.customer, ...customer };
            occurrence.order_items[idxi] = { ...oi, customer: customer }
          }
        });
      }
      occurrences_copy[idxo] = occurrence;
    });
    setDayOccurences(occurrences_copy);
    setEditedCustomer(null);
  }
  /* ----------------------
  handleOrderUpdated
  ---------------------- */
  const handleOrderUpdated = (order) => {
    const occurrences_copy = _.clone(dayOccurrences);
    occurrences_copy.forEach((occurrence, idxo) => {
      if (occurrence.order_items.length > 0) {
        occurrence.order_items.forEach((oi, idxi) => {
          if (oi.order.ordId === order.ordId) {
            occurrence.order_items[idxi] = { ...oi, order: order }
          }
        });
      }
      occurrences_copy[idxo] = occurrence;
    });
    setDayOccurences(occurrences_copy);
    setEditedOrder(null);
  }
  /* ----------------------
  showOccurrenceModal
  ---------------------- */
  const showOccurrenceModal = async (ocId) => {
    const qs = '?include=pickup,order_items,customers,cities,streets';
    const response = await API.get('/admin/occurrence/' + ocId + qs);
    setEditedOccurrence({ ...response.data });
  }
  /* ----------------------
  eventClassNames
  ---------------------- */
  const eventClassNames = (eventInfo) => {
  }
  /* ----------------------
  handleEvents
  ---------------------- */
  const handleEvents = (events) => {
  }
  /* ----------------------
  OrderItem
  ---------------------- */
  const OrderItem = ({ oi, po, setEditedOrder, setEditedCustomer, download }) => (
    (oi.order.ordStatus !== 'canceled' || true) ? (
      <tr className={`order-item order-${oi.order.ordStatus}`}>
        <td className='text-center'>
          <span className={`flow-icon ${oi.oiFlow}`}></span>
        </td>
        <td className='text-center text-nowrap'>
          <div className='order-info'>
            <span className='d-inline-block'>
              {oi.order.ordNumber}
              <span
                className='text-yellow pointer ms-2'
                onClick={e => download(`/utils/file?path=${oi.order.ordPdfSummaryFilename}`)}
                title="Télécharger le bon de commande original"
              >
                <AiOutlineDownload size={16} />
              </span>
            </span>
            <br />
            {oi.order.ordUposNumber !== '' ? (
              <span>
                {oi.order.ordUposNumber} <a
                  className='text-yellow pointer ms-1'
                  onClick={e => download(`/utils/file?path=${oi.order.ordUposPdf}`)}
                  title="Télécharger le bon de commande UPOS"
                >
                  <AiOutlineDownload size={16} />
                </a>
              </span>
            ) : (po.poDate < config.today ? (
              <span className='text-danger' title="Le bon de commande UPOS n'a pas été envoyé">
                BDC UPOS manquant
              </span>
            ) : (
              <></>
            ))}
            {oi.order.ordStatus === 'canceled' && <span><br />Annulée</span>}
          </div>
        </td>
        <td className='text-center'>
          <span className='mb-1 d-inline-block'>
            {oi.customer.custName} {oi.customer.custFirstname}
            <span
              className='text-yellow pointer ms-2'
              onClick={e => setEditedCustomer(oi.customer)}
              title='Afficher la fiche client'
            >
              <FiExternalLink size={16} />
            </span>
          </span>
          <br />
          {oi.customer.user.userPhone}
        </td>
        <td className='text-center'>{formatPrice(oi.order.ordToBePaid)}</td>
        <td className='text-center'>{formatPrice(oi.order.ordPaid)}</td>
        <td className={`text-center ${oi.order.ordStatus === 'handled' ? (oi.order.ordBalance == 0 ? 'text-success' : 'text-danger') : ''}`}  >
          {formatPrice(oi.order.ordBalance)}
        </td>
        <td className='text-center'>
          <span className="pointer" onClick={e => setEditedOrder(oi.order)}>
            <FiExternalLink size={16} className="text-warning" />
          </span>
        </td>
      </tr>)
      :
      null
  );
  return (
    <div id="admin-pickups-calendar-v2">
      <div className='container'>
        <div className='row'>
          <div className='col-sm-8'>
            <div className='content-title'>
              <h1>{date}</h1>
            </div>
            {dayOccurrences.length > 0 ?
              <table className='table table-sm table-bordered'>
                <thead className='spacer'>
                  <tr>
                    <th style={{}}></th>
                    <th style={{}}></th>
                    <th style={{}}></th>
                    <th style={{}}></th>
                    <th style={{}}></th>
                    <th style={{}}></th>
                    <th style={{}}>
                    </th>
                  </tr>
                </thead>
                {stats && dayOccurrences && dayOccurrences.map(po => (
                  <tbody className="occurrence" key={po.poId}>
                    <tr>
                      <th colSpan={5} >
                        <span className='fs-6'>
                          Tournée &laquo;{po.pickup.picName}&raquo;
                        </span>
                      </th>
                      <th className='text-center'>
                        <span
                          className='text-warning pointer'
                          onClick={e => showOccurrenceModal(po.poId)}
                          title="Afficher les détails de la tournée">
                          <FiExternalLink size={20}
                          />
                        </span>
                      </th>
                      <th className='text-center'>
                        <span
                          className='text-warning pointer'
                          onClick={e => download('/admin/occurrence/' + po.poId + '/pdf')}
                          title="Télécharger la fiche de la tournée"
                        >
                          <AiOutlineDownload size={26} />
                        </span>
                      </th>
                    </tr>
                    <tr className='fs-10 '>
                      <th></th>
                      <th className='text-center'><span className='fs-9'>NUM CMDE</span></th>
                      <th className='text-center'><span className='fs-9'>CLIENT</span></th>
                      <th className='text-center'><span className='fs-9'>TOTAL</span></th>
                      <th className='text-center'><span className='fs-9'>REGLÉ</span></th>
                      <th className='text-center'><span className='fs-9'>SOLDE</span></th>
                      <th className='text-center'><span className='fs-9'></span></th>
                    </tr>
                    {stats[po.poId]?.collectes.length > 0 &&
                      stats[po.poId].collectes.map(oi => (
                        <OrderItem
                          key={oi.order.ordNumber}
                          oi={oi}
                          po={po}
                          setEditedOrder={setEditedOrder}
                          setEditedCustomer={setEditedCustomer}
                          download={download}
                        />
                      ))
                    }
                    {stats[po.poId]?.restitutions.length > 0 &&
                      stats[po.poId].restitutions.map(oi => (
                        <OrderItem
                          key={oi.order.ordNumber}
                          oi={oi}
                          po={po}
                          setEditedOrder={setEditedOrder}
                          setEditedCustomer={setEditedCustomer}
                          download={download}
                        />
                      ))
                    }
                  </tbody>
                ))}
              </table>
              :
              <div className='text-center fw-bold text-secondary py-5'>
                Aucune collecte ou restitution  le {date.toLowerCase()}.
              </div>
            }
          </div>
          <div className='col-sm-4 side calendar'>
            <div className='mb-3'>
              <FullCalendar
                locales={allLocales} locale={'fr'}
                plugins={[interactionPlugin, dayGridPlugin]}
                headerToolbar={{
                  left: 'prev,next',
                  center: 'title',
                  right: 'customVersionButton',
                }}
                initialView="dayGridMonth"
                editable={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={false}
                dayMaxEventRows={true}
                initialDate={today}
                weekNumbers={true}
                aspectRatio={0.9}
                expandRows={true}
                weekends={weekendsVisible}
                events={events} // alternatively, use the `events` setting to fetch from a feed
                select={handleDateSelect}
                eventContent={renderEventContent} // custom render function
                datesSet={handleDatesSet}
                eventClassNames={eventClassNames}
                eventClick={handleEventClick}
                eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                customButtons={{
                  customVersionButton: {
                    text: '» Version 1',

                    click: () => navigate('/appAdmin/planningv1'), // Fonction de navigation
                  },
                }}
              />
            </div>
            <p className='text-right'>

            </p>
          </div>

        </div>
      </div>
      {(editedOccurrence || editedOrder || editedCustomer) && (
        <Modal
          show={true}
          onHide={() => {
            setEditedOccurrence(null);
            setEditedOrder(null);
            setEditedCustomer(null);
          }}
          size={editedOrder ? "xl" : "lg"}
          className={editedOccurrence ? "admin-pickup-modal" : ""}
        >
          <Modal.Header closeButton>
            {editedOccurrence ? (
              <Modal.Title>
                Tournée &laquo;{editedOccurrence.pickup.picName}&raquo; du {editedOccurrence.poDate}
              </Modal.Title>
            ) : editedOrder ? (
              <strong>{editedOrder.ordNumber}</strong>
            ) : (
              `${editedCustomer.custFirstname} ${editedCustomer.custName}`
            )}
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingTop: '40px' }}>
              {editedOccurrence && (
                <AdminPickupOccurrence occurrence={editedOccurrence} paidOrderOnly={false} />
              )}
              {editedOrder && (
                <AdminOrderDetail orderId={editedOrder.ordId} onUpdated={handleOrderUpdated} />
              )}
              {editedCustomer && (
                <AdminCustomer customer={editedCustomer} onSaved={handleCustomerUpdated} />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* Footer content if needed */}
          </Modal.Footer>
        </Modal>
      )}
    </div>);
}
export default AdminPickupCalendarV2;
