// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes swal2-show {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

#swal2-html-container {
  padding: 36px 0 0 0 !important;
  margin: 0 25px 25px !important;
  -webkit-user-select: text;
          user-select: text;

}

div.swal2-actions {
  margin: 0em auto 0 !important;
}

div.swal2-modal {
  padding: 10px 15px;
}


.swal2-confirm {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
  color: #000 !important;
}


.swal2-confirm:hover,
.swal2-confirm:focus,
.swal2-confirm:active {
  background-color: #ffca2c !important;
  border-color: #ffc720 !important;
}`, "",{"version":3,"sources":["webpack://./src/css/sweetalert.css"],"names":[],"mappings":"AAAA;EACE;IACE,mBAAmB;IACnB,UAAU;EACZ;;EAEA;IACE,mBAAmB;IACnB,UAAU;EACZ;AACF;;AAEA;EACE,8BAA8B;EAC9B,8BAA8B;EAC9B,yBAAiB;UAAjB,iBAAiB;;AAEnB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;AACpB;;;AAGA;EACE,oCAAoC;EACpC,gCAAgC;EAChC,sBAAsB;AACxB;;;AAGA;;;EAGE,oCAAoC;EACpC,gCAAgC;AAClC","sourcesContent":["@keyframes swal2-show {\r\n  0% {\r\n    transform: scale(1);\r\n    opacity: 0;\r\n  }\r\n\r\n  100% {\r\n    transform: scale(1);\r\n    opacity: 1;\r\n  }\r\n}\r\n\r\n#swal2-html-container {\r\n  padding: 36px 0 0 0 !important;\r\n  margin: 0 25px 25px !important;\r\n  user-select: text;\r\n\r\n}\r\n\r\ndiv.swal2-actions {\r\n  margin: 0em auto 0 !important;\r\n}\r\n\r\ndiv.swal2-modal {\r\n  padding: 10px 15px;\r\n}\r\n\r\n\r\n.swal2-confirm {\r\n  background-color: #ffc107 !important;\r\n  border-color: #ffc107 !important;\r\n  color: #000 !important;\r\n}\r\n\r\n\r\n.swal2-confirm:hover,\r\n.swal2-confirm:focus,\r\n.swal2-confirm:active {\r\n  background-color: #ffca2c !important;\r\n  border-color: #ffc720 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
