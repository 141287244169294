// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete-input {
  position: relative;
  color: #111;
  flex: 1 1;
}

.autocomplete-input .autocomplete-container {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  max-height: 200px;
  z-index: 9999;
  overflow-y: auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.autocomplete-input .suggestion {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-input .suggestion:hover,
.autocomplete-input .suggestion.selected {
  background-color: #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/components/helpers/InputAutocomplete/input-autocomplete.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;;EAEE,yBAAyB;AAC3B","sourcesContent":[".autocomplete-input {\r\n  position: relative;\r\n  color: #111;\r\n  flex: 1;\r\n}\r\n\r\n.autocomplete-input .autocomplete-container {\r\n  position: absolute;\r\n  top: 45px;\r\n  left: 0;\r\n  width: 100%;\r\n  max-height: 200px;\r\n  z-index: 9999;\r\n  overflow-y: auto;\r\n  background-color: #f9f9f9;\r\n  border: 1px solid #ddd;\r\n  border-radius: 4px;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.autocomplete-input .suggestion {\r\n  padding: 8px;\r\n  cursor: pointer;\r\n}\r\n\r\n.autocomplete-input .suggestion:hover,\r\n.autocomplete-input .suggestion.selected {\r\n  background-color: #e0e0e0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
