// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switcher {
  width: 36px;
  height: 18px;
  border-radius: 24px;
  border: 1px solid #999;
  position: relative;
  transition: all 200ms;
  background-color: #eee;
  overflow: hidden;
  cursor: pointer;
}

.switcher.disabled {
  cursor: no-drop;
}

.switcher .handler {
  display: inline-block;
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  transform: translateX(-50%);
  top: 1;
  transition: left 200ms;
  background-color: #333;
  border-radius: 24px;
}

.switcher.off .handler {
  left: 0;
  transform: translateX(0);

}

.switcher.on .handler {
  left: 100%;
  transform: translateX(-100%);
  background-color: var(--active);

}



.switcher-sm {
  width: 24px;
  height: 12px;
  border-radius: 24px;
}

.switcher-sm .handler {
  height: 10px;
  width: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/helpers/Switcher/switcher.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,SAAS;EACT,2BAA2B;EAC3B,MAAM;EACN,sBAAsB;EACtB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,OAAO;EACP,wBAAwB;;AAE1B;;AAEA;EACE,UAAU;EACV,4BAA4B;EAC5B,+BAA+B;;AAEjC;;;;AAIA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".switcher {\r\n  width: 36px;\r\n  height: 18px;\r\n  border-radius: 24px;\r\n  border: 1px solid #999;\r\n  position: relative;\r\n  transition: all 200ms;\r\n  background-color: #eee;\r\n  overflow: hidden;\r\n  cursor: pointer;\r\n}\r\n\r\n.switcher.disabled {\r\n  cursor: no-drop;\r\n}\r\n\r\n.switcher .handler {\r\n  display: inline-block;\r\n  position: absolute;\r\n  height: 16px;\r\n  width: 16px;\r\n  left: 50%;\r\n  transform: translateX(-50%);\r\n  top: 1;\r\n  transition: left 200ms;\r\n  background-color: #333;\r\n  border-radius: 24px;\r\n}\r\n\r\n.switcher.off .handler {\r\n  left: 0;\r\n  transform: translateX(0);\r\n\r\n}\r\n\r\n.switcher.on .handler {\r\n  left: 100%;\r\n  transform: translateX(-100%);\r\n  background-color: var(--active);\r\n\r\n}\r\n\r\n\r\n\r\n.switcher-sm {\r\n  width: 24px;\r\n  height: 12px;\r\n  border-radius: 24px;\r\n}\r\n\r\n.switcher-sm .handler {\r\n  height: 10px;\r\n  width: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
