// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#CartDetails {}

#CartDetails table {
  background-color: var(--blue-1);
}



#CartDetails table tbody td {
  background-color: transparent;
}


#CartDetails table thead th,
#CartDetails table thead th,
#CartDetails table tbody th {
  background-color: var(--blue-2);



}




#CartDetails .add-comment {
  font-size: 95%;

}



@media screen and (max-width: 767px) {

  #CartDetails th.picture,
  #CartDetails td.picture {
    display: none;
  }

  #CartDetails th,
  #CartDetails td {
    padding: 2px 3px !important;
    width: auto !important;
  }


}`, "",{"version":3,"sources":["webpack://./src/components/cart/cart-details.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,+BAA+B;AACjC;;;;AAIA;EACE,6BAA6B;AAC/B;;;AAGA;;;EAGE,+BAA+B;;;;AAIjC;;;;;AAKA;EACE,cAAc;;AAEhB;;;;AAIA;;EAEE;;IAEE,aAAa;EACf;;EAEA;;IAEE,2BAA2B;IAC3B,sBAAsB;EACxB;;;AAGF","sourcesContent":["#CartDetails {}\r\n\r\n#CartDetails table {\r\n  background-color: var(--blue-1);\r\n}\r\n\r\n\r\n\r\n#CartDetails table tbody td {\r\n  background-color: transparent;\r\n}\r\n\r\n\r\n#CartDetails table thead th,\r\n#CartDetails table thead th,\r\n#CartDetails table tbody th {\r\n  background-color: var(--blue-2);\r\n\r\n\r\n\r\n}\r\n\r\n\r\n\r\n\r\n#CartDetails .add-comment {\r\n  font-size: 95%;\r\n\r\n}\r\n\r\n\r\n\r\n@media screen and (max-width: 767px) {\r\n\r\n  #CartDetails th.picture,\r\n  #CartDetails td.picture {\r\n    display: none;\r\n  }\r\n\r\n  #CartDetails th,\r\n  #CartDetails td {\r\n    padding: 2px 3px !important;\r\n    width: auto !important;\r\n  }\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
