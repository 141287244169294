// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AdminCatalog {}


#AdminCatalog .categories-tree {
  background-color: #fafafa;
}

#AdminCatalog .categories-tree ul {
  list-style: none;
  margin: 0 0;
  padding: 0 0;
}

#AdminCatalog .categories-tree li {
  margin: 0 0;
  padding: 0 0;
}

#AdminCatalog .categories-tree li>div:hover {
  background-color: #f0f0f0;
}

#AdminCatalog input:read-only {
  background-color: transparent;
  border: none;
}

#AdminCatalog .categories-tree>ul>li {
  border-bottom: 1px solid white;
}

#AdminCatalog .categories-tree>ul>li:last-child {
  border-bottom: none;
}

#AdminCatalog .categories-tree ul ul {
  font-size: 90%
}

#AdminCatalog .drag-handle {
  display: inline-block;
  text-align: left;
  width: 30px;

}

#AdminCatalog .fs-3 {
  font-size: 1rem !important;
}


#AdminCatalog .fs-4 {
  font-size: 0.9rem !important;
}

#AdminCatalog .fs-5 {
  font-size: 0.8rem !important;
}

#AdminCatalog .fs-6 {
  font-size: 0.7rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/catalog/admin/admin-catalog.css"],"names":[],"mappings":"AAAA,eAAe;;;AAGf;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;AACF;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,WAAW;;AAEb;;AAEA;EACE,0BAA0B;AAC5B;;;AAGA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["#AdminCatalog {}\r\n\r\n\r\n#AdminCatalog .categories-tree {\r\n  background-color: #fafafa;\r\n}\r\n\r\n#AdminCatalog .categories-tree ul {\r\n  list-style: none;\r\n  margin: 0 0;\r\n  padding: 0 0;\r\n}\r\n\r\n#AdminCatalog .categories-tree li {\r\n  margin: 0 0;\r\n  padding: 0 0;\r\n}\r\n\r\n#AdminCatalog .categories-tree li>div:hover {\r\n  background-color: #f0f0f0;\r\n}\r\n\r\n#AdminCatalog input:read-only {\r\n  background-color: transparent;\r\n  border: none;\r\n}\r\n\r\n#AdminCatalog .categories-tree>ul>li {\r\n  border-bottom: 1px solid white;\r\n}\r\n\r\n#AdminCatalog .categories-tree>ul>li:last-child {\r\n  border-bottom: none;\r\n}\r\n\r\n#AdminCatalog .categories-tree ul ul {\r\n  font-size: 90%\r\n}\r\n\r\n#AdminCatalog .drag-handle {\r\n  display: inline-block;\r\n  text-align: left;\r\n  width: 30px;\r\n\r\n}\r\n\r\n#AdminCatalog .fs-3 {\r\n  font-size: 1rem !important;\r\n}\r\n\r\n\r\n#AdminCatalog .fs-4 {\r\n  font-size: 0.9rem !important;\r\n}\r\n\r\n#AdminCatalog .fs-5 {\r\n  font-size: 0.8rem !important;\r\n}\r\n\r\n#AdminCatalog .fs-6 {\r\n  font-size: 0.7rem !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
