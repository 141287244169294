// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#abstilent {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #bd809f;
  height: 14px;
  z-index: 10000;
}

#abstilent .progress-bar {
  height: 100%;
  background-color: #bd809f;
  width: 96%;
  left: 2%;
  position: absolute;

}

#abstilent .progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #d96680;
  border-radius: 0 !important;
}




#abstilent .milestone {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  border-left: 2px white solid;

}


#abstilent .week {
  opacity: 0.7;
  background-color: transparent;
  font-size: 0.6rem;
  border-left: 1px solid #eee;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/helpers/AbstiLent/abstilent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,UAAU;EACV,QAAQ;EACR,kBAAkB;;AAEpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,2BAA2B;AAC7B;;;;;AAKA;EACE,kBAAkB;EAClB,MAAM;EACN,YAAY;EACZ,UAAU;EACV,4BAA4B;;AAE9B;;;AAGA;EACE,YAAY;EACZ,6BAA6B;EAC7B,iBAAiB;EACjB,2BAA2B;EAC3B,YAAY;AACd","sourcesContent":["#abstilent {\r\n  position: fixed;\r\n  bottom: 0;\r\n  width: 100%;\r\n  background-color: #bd809f;\r\n  height: 14px;\r\n  z-index: 10000;\r\n}\r\n\r\n#abstilent .progress-bar {\r\n  height: 100%;\r\n  background-color: #bd809f;\r\n  width: 96%;\r\n  left: 2%;\r\n  position: absolute;\r\n\r\n}\r\n\r\n#abstilent .progress {\r\n  position: absolute;\r\n  bottom: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: #d96680;\r\n  border-radius: 0 !important;\r\n}\r\n\r\n\r\n\r\n\r\n#abstilent .milestone {\r\n  position: absolute;\r\n  top: 0;\r\n  height: 100%;\r\n  width: 1px;\r\n  border-left: 2px white solid;\r\n\r\n}\r\n\r\n\r\n#abstilent .week {\r\n  opacity: 0.7;\r\n  background-color: transparent;\r\n  font-size: 0.6rem;\r\n  border-left: 1px solid #eee;\r\n  color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
