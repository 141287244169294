import React, { useEffect, useState } from 'react';


import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Importe useSelector
import { fetchConfig } from './redux/configSlice';
import { updateUser, clearUser } from './redux/userSlice';
import API from './api';
// Layouts
import AppLayout from './layouts/AppLayout';
// Routes Components
import HomeDefault from './components/homes/HomeDefault';
import CustomerHome from './components/customers/CustomerHome';
import Order from './components/orders/Order';
import OrderConfirm from './components/orders/OrderConfirm';
import OrderPayment from './components/orders/OrderPayment';
// import UserPdwRecovery from './components/user/UserPdwRecovery';
import AdminHome from './components/homes/AdminHome';
import AdminPickupCalendar from './components/pickups/admin/AdminPickupCalendar';
import AdminPickupCalendarV2 from './components/pickups/admin/AdminPickupCalendarV2';
import AdminOrders from './components/orders/admin/AdminOrders';
import AdminPickups from './components/pickups/admin/AdminPickups';
import AdminCities from './components/cities/AdminCities';
import AdminCustomersList from './components/customers/admin/AdminCustomersList';
import AdminCatalog from './components/catalog/admin/AdminCatalog';
import AdminPartners from './components/partners/admin/AdminPartners';
import AdminWorkshopConnect from './components/workshop/admin/AdminWorkshopConnect';
import DiscountCodesList from './components/discountCodes/admin/DiscountCodesList';
// Styles 
import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/app.css';
// Route Definitions
const routes = [
  { path: "/", element: <HomeDefault /> },
  { path: "/order", element: <Order /> },
  { path: "/order/confirm", element: <OrderConfirm />, requireAuth: false },
  { path: "/order/:ordId/payment", element: <OrderPayment /> },
  { path: "/customer/account", element: <CustomerHome />, requireAuth: true },
  { path: "/customer/params", element: <CustomerHome />, requireAuth: true },
  { path: "/customer/orders", element: <CustomerHome />, requireAuth: true },
  { path: "/fr/order", element: <Order /> },
  { path: "/fr/order/confirm", element: <Order /> },
  { path: "/fr/order/:ordId/payment", element: <OrderPayment /> },
  { path: "/fr/customer/account", element: <CustomerHome />, requireAuth: true },
  { path: "/fr/customer/params", element: <CustomerHome />, requireAuth: true },
  { path: "/fr/customer/orders", element: <CustomerHome />, requireAuth: true },
  { path: "/fr/customer/orders/:ordId", element: <CustomerHome />, requireAuth: true },
  /* { path: "/user/pwd_recovery", element: <UserPdwRecovery /> }, */
  { path: "/appAdmin", element: <AdminHome />, requireAdmin: true },
  { path: "/appAdmin/planningv1", element: <AdminPickupCalendar />, requireAdmin: true },
  { path: "/appAdmin/planning", element: <AdminPickupCalendarV2 />, requireAdmin: true },
  { path: "/appAdmin/orders", element: <AdminOrders />, requireAdmin: true },
  { path: "/appAdmin/pickups", element: <AdminPickups />, requireAdmin: true },
  { path: "/appAdmin/cities", element: <AdminCities />, requireAdmin: true },
  { path: "/appAdmin/customers", element: <AdminCustomersList />, requireAdmin: true },
  { path: "/appAdmin/catalog", element: <AdminCatalog />, requireAdmin: true },
  { path: "/appAdmin/partners", element: <AdminPartners />, requireAdmin: true },
  { path: "/appAdmin/workshop/connect", element: <AdminWorkshopConnect />, requireAdmin: true },
  { path: "/appAdmin/discountCodes", element: <DiscountCodesList />, requireAdmin: true },
];
function App() {


  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adminAuthToken = params.get('adminAuthToken');
    if (adminAuthToken) {
      checkToken(adminAuthToken, params.get('userId'));
    } else {
      setAllowed(true);
    }


    // Setup message listener
    const handleMessage = (event) => {
      //      console.log('Received message:', event.data.type);
      if (event.data && event.data.type === 'userSignOut') {
        dispatch(clearUser());
      }
    };
    window.addEventListener("message", handleMessage);
    // Cleanup listener on unmount
    return () => window.removeEventListener("message", handleMessage);
  }, []);
  useEffect(() => {
    dispatch(fetchConfig());
  }, [dispatch]);
  const checkToken = async (adminAuthToken, userId) => {
    let querystring = 'adminAuthToken=' + adminAuthToken;
    querystring += '&userId=' + userId;
    const response = await API.get('/admin/signin?' + querystring);
    if (response.data.isAdmin == true) {
      setAllowed(true)
      window.localStorage.setItem('adminToken', response.data.token);
      dispatch(updateUser(response.data));
      window.sessionStorage.setItem('backoffice_url', '/appAdmin');

      window.location.href = '/appAdmin';
    } else {
      window.location.href = '/';
    }
  }
  let windowHeight = document.documentElement.scrollHeight
  if (window !== window.parent) {
    /*
    window.setInterval(() => {
      var element = document.getElementById('root');
      var rect = element.getBoundingClientRect();
      if (windowHeight != rect.height) {
        windowHeight = rect.height;
        window.parent.postMessage({ windowHeight: windowHeight }, 'https://laffairearepasser.fr');
      }

    }, 1000);
    */
  }



  return (
    <>
      {allowed &&
        <Router>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path}
                path={route.path}
                element={<AppLayout requireAuth={route.requireAuth} requireAdmin={route.requireAdmin} >
                  {route.element}
                </AppLayout>}
              />
            ))}
          </Routes>
        </Router>
      }
    </>
  );
}
export default App;
