import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from './../../api';
import CheckoutForm from './../stripe/CheckoutForm';
import { Link, useParams, useNavigate } from 'react-router-dom';
import './order.css';
import { debug, download, formatDate, swalAlert } from './../../utils';

const OrderPayment = () => {
  const [queryParams, setQueryParams] = useState({ status: '', success: null });
  const { ordId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [options, setOptions] = useState(null);
  const [paymentResult, setPaymentResult] = useState('');
  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const queryParams = {};
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    console.log(queryParams)
    setQueryParams(queryParams);
  }, []);
  useEffect(() => {
    get_order_payment_info();
  }, [ordId]);
  useEffect(() => {
  }, [order, queryParams]);
  const get_order_payment_info = async () => {
    try {
      const response = await API.get('/order/' + ordId + '/payment_info');
      setOrder(response.data);
    } catch (e) {
    }
  };
  return (
    <div id="OrderPayment">
      <div className="container">
        {debug(queryParams)}
        {order && queryParams?.status !== '00000' &&
          <>
            {order.payment_form !== '' ?
              <p>Nous vous invitons à régler votre commande via notre solution de paiement en ligne sécurisée Up2Pay (Crédit Agricole).</p>
              :
              order.ordPaymentAt != '' &&
              <p className='text-green'>Cette commande a été réglée le {formatDate(order.ordPaymentAt)}.</p>
            }
            <dl className='mb-3'>
              <dt>Rappel de votre commande : </dt>
              <dd>Numéro de commande : {order.ordNumber}</dd>
              <dd>Date : {formatDate(order.ordCreatedAt)}</dd>
              <dd>Montant : {order.ordTotalIT} €</dd>
              <dd>Solde : {order.ordBalance} €</dd>
              {order.ordUposPdf !== '' &&
                <dd>
                  Bon de commande : <span onClick={e => download('/utils/file?path=' + order.ordUposPdf)} className='px-2 pointer text-blue'>Afficher</span>
                </dd>
              }
            </dl>
            {(order.payment_form !== '') ?
              <div dangerouslySetInnerHTML={{ __html: order.payment_form }} />
              :
              <p className='text-green'>Cette commande ne peut être payée pour le moment.</p>
            }
            {debug(order)}
          </>
        }
        {queryParams.status == '00000' && queryParams.success == '' && <>
          <h5>Merci !</h5>
          <p className='mb-3'>Votre paiement a bien été enregistré.</p>

          <p>
            <Link to='/fr/order' className='btn btn-outline-secondary'>Je souhaite passer une nouvelle de commande</Link>
          </p>
        </>}
      </div>
    </div>
  );
};
export default OrderPayment;
