// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploader {}

.uploader .drop-zone {
  line-height: 1;
  text-align: center;
}

.uploader .large {
  flex: 1 1;
  padding: 25px;

}

.uploader .small {
  width: 180px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploader .btn-upload {
  background-color: transparent;
  border: none;
  font-size: 3rem;
  opacity: 0.7;
  transition: all 300ms;
  color: #ffc107;
}

.uploader .btn-upload:hover {
  transform: scale(1.2);
  opacity: 1;
}

.uploader .alert .btn-close {
  position: absolute;
  top: 10px;
  right: 10px
}`, "",{"version":3,"sources":["webpack://./src/components/helpers/uploader/uploader.css"],"names":[],"mappings":"AAAA,WAAW;;AAEX;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,aAAa;;AAEf;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT;AACF","sourcesContent":[".uploader {}\r\n\r\n.uploader .drop-zone {\r\n  line-height: 1;\r\n  text-align: center;\r\n}\r\n\r\n.uploader .large {\r\n  flex: 1;\r\n  padding: 25px;\r\n\r\n}\r\n\r\n.uploader .small {\r\n  width: 180px;\r\n  max-width: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\n.uploader .btn-upload {\r\n  background-color: transparent;\r\n  border: none;\r\n  font-size: 3rem;\r\n  opacity: 0.7;\r\n  transition: all 300ms;\r\n  color: #ffc107;\r\n}\r\n\r\n.uploader .btn-upload:hover {\r\n  transform: scale(1.2);\r\n  opacity: 1;\r\n}\r\n\r\n.uploader .alert .btn-close {\r\n  position: absolute;\r\n  top: 10px;\r\n  right: 10px\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
