// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#backoffice-header {
  background-color: #eee;
  padding: 0 10px;
}

#backoffice-header .row {

  align-items: center;
}

#backoffice-header .col-center a {
  padding: 5px 10px;
  display: inline-block;
  border-right: 1px solid white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.9rem;
}

#backoffice-header .col-center a:hover {
  text-decoration: underline;
}

#backoffice-header .col-center a:last-child {


  border-right: 0px solid white;
}`, "",{"version":3,"sources":["webpack://./src/components/common/backoffice-header.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;AACjB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,6BAA6B;EAC7B,qBAAqB;EACrB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;;EAGE,6BAA6B;AAC/B","sourcesContent":["#backoffice-header {\r\n  background-color: #eee;\r\n  padding: 0 10px;\r\n}\r\n\r\n#backoffice-header .row {\r\n\r\n  align-items: center;\r\n}\r\n\r\n#backoffice-header .col-center a {\r\n  padding: 5px 10px;\r\n  display: inline-block;\r\n  border-right: 1px solid white;\r\n  text-decoration: none;\r\n  text-transform: uppercase;\r\n  font-size: 0.9rem;\r\n}\r\n\r\n#backoffice-header .col-center a:hover {\r\n  text-decoration: underline;\r\n}\r\n\r\n#backoffice-header .col-center a:last-child {\r\n\r\n\r\n  border-right: 0px solid white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
