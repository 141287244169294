import React, { useState, useEffect } from 'react';
import './abstilent.css';
import { isDev } from './../../../utils'
const AbstiLent = () => {
  const startDate = new Date('2024-01-14'); // Date de début
  const milestone1 = new Date('2024-02-14'); // Jalon du 14/02
  const milestone2 = new Date('2024-03-07'); // Jalon du 07/03
  const endDate = new Date('2024-03-28');   // Date de fin
  const currentDate = new Date();           // Date actuelle
  const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60)); // Nombre total de jours
  const daysPassed = Math.ceil((currentDate - startDate) / (1000 * 60 * 60)); // Jours passés
  const progress = (daysPassed / totalDays) * 100; // Calcul du pourcentage de progression
  const milestone1Position = (milestone1 - startDate) / (endDate - startDate) * 100;
  const milestone2Position = (milestone2 - startDate) / (endDate - startDate) * 100;
  return (
    <>
      {isDev() &&
        <div id="abstilent">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}>
            </div>

            <div className="milestone" style={{ left: `${milestone1Position}%` }}></div>
            <div className="milestone" style={{ left: `${milestone2Position}%` }}></div>
            {Array.from({ length: totalDays / 7 }, (_, index) => (
              <div
                key={index}
                className="milestone week"
                style={{ left: `${(startDate.getTime() + (index * 7 * 24 * 60 * 60 * 1000) - startDate.getTime()) / (endDate.getTime() - startDate.getTime()) * 100}%` }}
              >{index}</div>
            ))}
          </div>
        </div>
      }
    </>
  );
};
export default AbstiLent;





