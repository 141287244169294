// OrderDetail.js
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { debug, download, formatDate, swalAlert } from './../../utils'
import { Link } from 'react-router-dom';
import API from '../../api';
import CartDetails from '../cart/CartDetails';

const OrderDetail = ({ ordId }) => {
  const [order, setOrder] = useState(null);
  const { t } = useTranslation();
  const [restitutionsSuggests, setRestitutionsSuggests] = useState([]);
  const [newRestitutionOccurrence, setNewRestitutionOccurrence] = useState(null);
  useEffect(() => {

    if (ordId) {
      API.get(`/order/${ordId}`).then(response => setOrder(response.data))
    }
  }, [ordId]);
  const getRestitutionsSuggests = async () => {
    const item = order.items.find(oi => oi.oiFlow === 'restitution' && oi.oiPickupId !== '');
    if (item) {
      try {
        const response = await API.get('/city/' + item.oiCityId + '/pickups_occurrences');
        setRestitutionsSuggests(response.data.filter(poc => poc.pocDate >= order.ordNearestRestitutionDate));
      } catch (e) {
      }
    }
  }
  const hanleRestitutionSuggestChange = (event) => {
    const occurrence = restitutionsSuggests.find(poc => poc.pocId === event.target.value);
    if (occurrence) {
      setNewRestitutionOccurrence(occurrence);
    }
  }
  const updateRestitutionSuggestChange = async () => {
    if (newRestitutionOccurrence) {
      const idx = order.items.findIndex(oi => oi.oiFlow === 'restitution' && oi.oiPickupId !== '');
      if (idx > -1) {
        const item = order.items[idx];
        if (item) {
          try {
            const response = await API.put(`/order/item/${item.oiId}`, { pickupCityOccurrence: newRestitutionOccurrence });
            const itemsCopy = [...order.items]
            itemsCopy[idx] = response.data;
            setOrder({ ...order, items: itemsCopy });
            setRestitutionsSuggests([]);
            setNewRestitutionOccurrence(null)
          } catch (error) {
            swalAlert('Une erreur est survenue.');
          }

        }
      }
    }
  }
  return (
    <div id="OrderDetail">
      {order && <div>
        <div className='row'>
          <div className='col-xl-6'>
            <table className="table table-sm">
              <tbody>
                <tr>
                  <th>Retrait : </th>
                  <td colSpan='2'>
                    {(() => {
                      const item = order.items.find(oi => oi.oiFlow === 'collecte' && oi.oiPickupId !== '');
                      if (!item) {
                        return 'A la repasserie';
                      }
                      const formattedDate = item.occurrence.poDate ? formatDate(item.occurrence.poDate, 'text') : 'A la repasserie';
                      const address = item.address.adrText + ', ' + item.city.citName;
                      return (
                        <>
                          {formattedDate} de {item.occurrence_city.pocSlotStart} à  {item.occurrence_city.pocSlotEnd}
                          <br />
                          {item.partner && <span>{item.partner.partName}, </span>}
                          {address}
                        </>
                      );
                    })()}
                  </td>
                </tr>

                <tr>
                  <th>Retour : </th>
                  {(() => {
                    const item = order.items.find(oi => oi.oiFlow === 'restitution' && oi.oiPickupId !== '');
                    const formattedDate = item?.occurrence.poDate ? formatDate(item.occurrence.poDate, 'text') : 'A la repasserie';
                    const address = item?.address.adrText + ', ' + item?.city.citName;
                    return (
                      (!item) ?
                        <td colSpan={2}>A la repasserie</td>
                        :
                        <>
                          <td>
                            {formattedDate}  de {item.occurrence_city.pocSlotStart} à  {item.occurrence_city.pocSlotEnd}
                            <br />
                            {item.partner && <span>{item.partner.partName}, </span>}
                            {address}
                            {restitutionsSuggests.length > 0 &&
                              <div className='my-3'>
                                <div className='input-group'>
                                  <select className='form-control form-control-sm' onChange={hanleRestitutionSuggestChange}>
                                    <option value=''>&raquo; Sélectionner</option>
                                    {restitutionsSuggests.map(rs => (
                                      <option key={rs.pocId} value={rs.pocId}>le {formatDate(rs.pocDate, 'text')} de {rs.pocSlotStart} à  {rs.pocSlotEnd} </option>
                                    ))}
                                  </select>
                                  <button className='btn btn-outline-blue btn-sm' onClick={updateRestitutionSuggestChange}>Valider</button>
                                  <button className='btn btn-outline-blue btn-sm' onClick={e => setRestitutionsSuggests([])}>Annuler</button>
                                </div>
                              </div>
                            }
                          </td>
                          <td>
                            {(item.occurrence_can_be_updated && restitutionsSuggests.length == 0) &&
                              <p className='pt-2 pb-0'>
                                <button className='btn btn-outline-blue btn-sm' onClick={getRestitutionsSuggests}>Modifier la date</button>
                              </p>
                            }
                          </td >
                        </>
                    );
                  })()}
                </tr>
                <tr>
                  <th>Etat de la commande :</th>
                  <td colSpan={2}>{t(`order-${order.ordStatus}`)}</td>
                </tr>
                {order.ordPaymentStatus !== '' && <tr>
                  <th>Etat du paiement</th>
                  <td colSpan={2}>
                    {t(`order-payment-${order.ordPaymentStatus}`)}
                    {(order.ordPaymentStatus == 'pending' && order.ordUposPdf !== '') &&
                      <><br /><Link className="mt-1 text-danger" to={`/fr/order/${order.ordId}/payment`}>Régler maintenant</Link></>
                    }
                  </td>
                </tr>
                }
                <tr>
                  <th>Total TTC</th>
                  <td colSpan={2}>{order.ordTotalIT} €</td>
                </tr>
                <tr>
                  <th>Total HT</th>
                  <td colSpan={2}>{order.ordTotalETDiscounted} € (TVA {order.ordVatRate}%)</td>
                </tr>
                <tr>
                  <th>À payer</th>
                  <td colSpan={2}>
                    {(order.ordStatus == 'pending' || order.ordUposPdf == '') ? <>En attente de chiffrage par la repasserie</> : <>{order.ordToBePaid} €</>}
                  </td>
                </tr>
                {order.ordUposPdf != '' && <tr>
                  <th></th>
                  <td colSpan={2}>
                    <span className='pointer d-inline-block text-danger text-decoration-underline' onClick={e => download(`/order/${order.ordId}/pdf`)}>
                      Afficher le bon de commande
                    </span>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
          {order.ordUposPdf == '' &&
            <CartDetails cart={order?.ordCart} disabled={true} />
          }
        </div>
      </div>}
      {debug(order, 1)}

    </div >
  )
}
export default OrderDetail

/*

*/