// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart table {
  width: 100%;
}

.cart table thead th {
  font-size: 0.7rem;
  text-align: center;
  background-color: #bbb;
}

.cart table td {
  font-size: 0.8rem
}

.cart table tfoot td {
  font-weight: bold;
}

.cart .btn {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/cart/cart.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".cart table {\r\n  width: 100%;\r\n}\r\n\r\n.cart table thead th {\r\n  font-size: 0.7rem;\r\n  text-align: center;\r\n  background-color: #bbb;\r\n}\r\n\r\n.cart table td {\r\n  font-size: 0.8rem\r\n}\r\n\r\n.cart table tfoot td {\r\n  font-weight: bold;\r\n}\r\n\r\n.cart .btn {\r\n  width: 200px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
