import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { debug, swalAlert, swalConfirm } from '../../../utils';

import API from './../../../api';
import { MdDelete } from 'react-icons/md';
const EditDiscountCodeModal = ({ show, handleClose, selectedCode, onSaved }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localCode, setLocalCode] = useState({ ...selectedCode });

  useEffect(() => {
    setIsEditing(!!selectedCode.dcId);
    setLocalCode({ ...selectedCode });
  }, [selectedCode]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalCode((prevCode) => ({
      ...prevCode,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      let response;
      API.handleError = false;

      if (isEditing) {
        response = await API.put(`/admin/discountCode/${localCode.dcId}`, localCode);
      } else {
        response = await API.post('/admin/discountCode', localCode);
      }
      swalAlert('Le code promo a été sauvegardé');
      if (onSaved) {
        onSaved(response.data);
      }
    } catch (e) {
      swalAlert(e.response?.data?.error?.message || 'Une erreur est survenue lors de la sauvegarde.');
    }
  }

  const handleDelete = async () => {
    const result = await swalConfirm('Confirmez-vous la suppression du code promo ?', {

      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      reverseButtons: true,
    });

    if (result) {
      try {
        API.handleError = false;
        // Appel API pour supprimer le code promo
        const response = await API.put(`/admin/discountCode/${localCode.dcId}`, {
          ...localCode,
          dcStatus: 'deleted',
        });
        swalAlert('le code promo a été supprimé');
        if (onSaved) {
          onSaved(response.data);
        }

        // Ferme la modale
        handleClose();

      } catch (e) {

        swalAlert(e.response?.data?.error?.message || 'Une erreur est survenue lors de la suppression.');
      }
    }
  };

  const isFormInvalid = () => {
    if (!localCode.dcCode || !localCode.dcDescription || !localCode.dcType || !localCode.dcAmount) {
      return true;
    }
    if (localCode.dcScope !== 'category') {
      return true;
    }
    if (localCode.dcType === 'percentage' && (localCode.dcAmount < 0 || localCode.dcAmount > 100)) {
      return true;
    }
    if (localCode.dcType === 'amount' && localCode.dcAmount <= 0) {
      return true;
    }
    return false;
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <div className="modal-header">
        <h5 className="modal-title">{isEditing ? `Éditer le code promo ${localCode.dcCode}` : "Création d'un code promo"}</h5>
        <button type="button" className="btn-close" onClick={handleClose}></button>
      </div>
      <div className="modal-body">
        {localCode && (
          <form>
            <div className="mb-3 w-50">
              <label htmlFor="formDcCode" className="form-label">Code promo *</label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="formDcCode"
                name="dcCode"
                value={localCode.dcCode}
                onChange={handleInputChange}
                disabled={isEditing}
              />
            </div>
            <div className="mb-3 w-100">
              <label htmlFor="formDcDescription" className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                id="formDcDescription"
                name="dcDescription"
                value={localCode.dcDescription}
                onChange={handleInputChange}
              />
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="formDcType" className="form-label">Type de réduction *</label>
                <select
                  className="form-select"
                  id="formDcType"
                  name="dcType"
                  value={localCode.dcType}
                  onChange={handleInputChange}
                >
                  <option value="">Sélectionnez un type</option>
                  <option value="percentage">Pourcentage</option>
                  <option value="amount">Montant fixe</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="formDcAmount" className="form-label">Montant *</label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    id="formDcAmount"
                    name="dcAmount"
                    value={localCode.dcAmount}
                    onChange={handleInputChange}
                    step="0.01"
                  />
                  <span className="input-group-text">
                    {localCode.dcType === 'percentage' ? '%' : '€'}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="formDcScope" className="form-label">Portée *</label>
                <select
                  className="form-select"
                  id="formDcScope"
                  name="dcScope"
                  value={localCode.dcScope}
                  onChange={handleInputChange}
                  disabled={isEditing}
                >
                  <option value="category">Catégorie de clients</option>
                  <option value="customer">Un client uniquement</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="formDcTarget" className="form-label">Cible *</label>
                {localCode.dcScope === 'category' ? (
                  <select
                    className="form-select"
                    id="formDcTarget"
                    name="dcTarget"
                    value={localCode.dcTarget}
                    onChange={handleInputChange}
                  >
                    <option value="all_clients">Tous les clients</option>
                    <option value="new_clients">Nouveaux clients (première commande)</option>
                  </select>
                ) : (
                  <div className='small py-2 text-red text-center'>Pour développement futur</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="formDcValidFrom" className="form-label">Valide à partir de</label>
                <input
                  type="date"
                  className="form-control"
                  id="formDcValidFrom"
                  name="dcValidFrom"
                  value={localCode.dcValidFrom}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="formDcValidUntil" className="form-label">Valide jusqu'à</label>
                <input
                  type="date"
                  className="form-control"
                  id="formDcValidUntil"
                  name="dcValidUntil"
                  value={localCode.dcValidUntil}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="formDcStatus" className="form-label">Statut</label>
                <select
                  className="form-select"
                  id="formDcStatus"
                  name="dcStatus"
                  value={localCode.dcStatus}
                  onChange={handleInputChange}
                >
                  <option value="active">Actif</option>
                  <option value="expired">Expiré</option>
                  <option value="disabled">Désactivé</option>
                </select>
              </div>

              <div className="col-md-6 mb-3 text-end">
                <label htmlFor="formDcStatus" className="form-label">&nbsp;</label><br />
                {isEditing && (
                  <button type="button" className="btn btn-danger btn-sm" onClick={handleDelete}>
                    <MdDelete size={16} /> Supprimer
                  </button>
                )}
              </div>
            </div>
            {debug(localCode, false)}
          </form>
        )}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={handleClose}>
          Annuler
        </button>
        <button type="button" className="btn btn-outline-warning" onClick={() => handleSave()} disabled={isFormInvalid() ? true : false}>
          Enregistrer les modifications
        </button>
      </div>
    </Modal>
  );
};

export default EditDiscountCodeModal;
