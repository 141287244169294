// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AdminOrderModalEditItem {}

#AdminOrderModalEditItem .pickups-list {
  font-size: smaller;
  list-style: none;
  margin: 0;
  padding: 0;
}

#AdminOrderModalEditItem .pickups-list li {}

#AdminOrderModalEditItem .pickups-list li>div:hover {
  background-color: rgb(var(--bs-light-rgb)) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/admin/admin-order-modal-edit-item.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;;AAEA,2CAA2C;;AAE3C;EACE,qDAAqD;AACvD","sourcesContent":["#AdminOrderModalEditItem {}\r\n\r\n#AdminOrderModalEditItem .pickups-list {\r\n  font-size: smaller;\r\n  list-style: none;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n#AdminOrderModalEditItem .pickups-list li {}\r\n\r\n#AdminOrderModalEditItem .pickups-list li>div:hover {\r\n  background-color: rgb(var(--bs-light-rgb)) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
