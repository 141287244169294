// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#order-details {
  font-size: 0.9rem;
}


#order-details .section {
  margin-bottom: 1.2rem;
}

.accordion-header button,
#order-details label {
  font-weight: bold;
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
}

#order-details label {
  display: block;
}

#order-details #CartDetails table {
  margin-bottom: 15px !important;
}

#order-details .order-infos table td {
  border: none !important
}


#order-details .uploader {
  width: 25px;
}

#order-details .uploader button.btn-upload {
  background-color: rgb(255, 193, 7);
  color: #111;
  font-size: 0.8rem;

  padding-top: 0 !important;
}


#order-details .uploader .alert {
  display: none
}`, "",{"version":3,"sources":["webpack://./src/components/orders/admin/admin-order-details.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;;AAGA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,iBAAiB;EACjB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE;AACF;;;AAGA;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,WAAW;EACX,iBAAiB;;EAEjB,yBAAyB;AAC3B;;;AAGA;EACE;AACF","sourcesContent":["#order-details {\r\n  font-size: 0.9rem;\r\n}\r\n\r\n\r\n#order-details .section {\r\n  margin-bottom: 1.2rem;\r\n}\r\n\r\n.accordion-header button,\r\n#order-details label {\r\n  font-weight: bold;\r\n  margin-bottom: 0.2rem;\r\n  font-size: 0.9rem;\r\n}\r\n\r\n#order-details label {\r\n  display: block;\r\n}\r\n\r\n#order-details #CartDetails table {\r\n  margin-bottom: 15px !important;\r\n}\r\n\r\n#order-details .order-infos table td {\r\n  border: none !important\r\n}\r\n\r\n\r\n#order-details .uploader {\r\n  width: 25px;\r\n}\r\n\r\n#order-details .uploader button.btn-upload {\r\n  background-color: rgb(255, 193, 7);\r\n  color: #111;\r\n  font-size: 0.8rem;\r\n\r\n  padding-top: 0 !important;\r\n}\r\n\r\n\r\n#order-details .uploader .alert {\r\n  display: none\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
