// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AdminCustomerOrders {}



.modal #AdminCustomer .tab-contents {
  height: 70vh;
}


#AdminCustomerOrders div.slider {
  width: 200%;
  transition: all 700ms;

}

#AdminCustomerOrders div.slider>div {
  max-height: 65vh;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/customers/admin/admin-customer.css"],"names":[],"mappings":"AAAA,sBAAsB;;;;AAItB;EACE,YAAY;AACd;;;AAGA;EACE,WAAW;EACX,qBAAqB;;AAEvB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":["#AdminCustomerOrders {}\r\n\r\n\r\n\r\n.modal #AdminCustomer .tab-contents {\r\n  height: 70vh;\r\n}\r\n\r\n\r\n#AdminCustomerOrders div.slider {\r\n  width: 200%;\r\n  transition: all 700ms;\r\n\r\n}\r\n\r\n#AdminCustomerOrders div.slider>div {\r\n  max-height: 65vh;\r\n  overflow-y: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
