// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AdminPickups .switcher .handler {
  background-color: white;
}

#AdminPickups .switcher-on {
  background-color: #c92a2a;
}

#AdminPickups .switcher-off {
  background-color: #484848;
}


.admin-pickup-modal {}`, "",{"version":3,"sources":["webpack://./src/components/pickups/admin/admin-pickups.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;;AAGA,qBAAqB","sourcesContent":["#AdminPickups .switcher .handler {\r\n  background-color: white;\r\n}\r\n\r\n#AdminPickups .switcher-on {\r\n  background-color: #c92a2a;\r\n}\r\n\r\n#AdminPickups .switcher-off {\r\n  background-color: #484848;\r\n}\r\n\r\n\r\n.admin-pickup-modal {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
