// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AdminPickupOccurrence .toolbar {
  background-color: #ddd;
  border-radius: 7px;
}

#AdminPickupOccurrence .switcher.on .handler {
  background-color: var(--bs-danger);
}

#AdminPickupOccurrence tbody td {
  font-size: 0.8rem
}

#AdminPickupOccurrence .flow-label {
  -webkit-user-select: none;
          user-select: none;
}

#AdminPickupOccurrence .flow-label svg {
  position: relative;
  top: -2px;
}`, "",{"version":3,"sources":["webpack://./src/components/pickups/admin/admin-pickup-occurrence.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE;AACF;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX","sourcesContent":["#AdminPickupOccurrence .toolbar {\r\n  background-color: #ddd;\r\n  border-radius: 7px;\r\n}\r\n\r\n#AdminPickupOccurrence .switcher.on .handler {\r\n  background-color: var(--bs-danger);\r\n}\r\n\r\n#AdminPickupOccurrence tbody td {\r\n  font-size: 0.8rem\r\n}\r\n\r\n#AdminPickupOccurrence .flow-label {\r\n  user-select: none;\r\n}\r\n\r\n#AdminPickupOccurrence .flow-label svg {\r\n  position: relative;\r\n  top: -2px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
