// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header-main-left {
  text-align: left;
  font-weight: bold;
}

#header-main-center {
  text-align: center;
  justify-content: center;
}

#header-main-right {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}



#header-main a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/common/frontoffice-header.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oCAAoC;AACtC;;;;AAIA;EACE,0BAA0B;AAC5B","sourcesContent":["#header-main-left {\r\n  text-align: left;\r\n  font-weight: bold;\r\n}\r\n\r\n#header-main-center {\r\n  text-align: center;\r\n  justify-content: center;\r\n}\r\n\r\n#header-main-right {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: flex-end !important;\r\n}\r\n\r\n\r\n\r\n#header-main a:hover {\r\n  text-decoration: underline;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
