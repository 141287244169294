// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#AdminCities {}

#AdminCities .table-wrapper {
  max-height: 60vh;
  overflow-y: scroll;
}

#AdminCities td {
  vertical-align: middle;
}

#AdminCities td .functions {
  width: 30px;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/components/cities/admin-cities.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":["#AdminCities {}\r\n\r\n#AdminCities .table-wrapper {\r\n  max-height: 60vh;\r\n  overflow-y: scroll;\r\n}\r\n\r\n#AdminCities td {\r\n  vertical-align: middle;\r\n}\r\n\r\n#AdminCities td .functions {\r\n  width: 30px;\r\n  text-align: right;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
