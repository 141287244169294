// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-quantity * {
  line-height: 1;
}

.input-quantity span.quantity {
  width: 20px;
}

.input-quantity span.quantity {
  width: 20px;
  font-size: 1rem;
}

.input-quantity svg {
  font-size: 1.1rem;
}

.input-quantity input[type="number"] {
  border: 1px solid #eaeaea !important;
  width: 40px;
  text-align: center;

}

.input-quantity.md span.quantity {
  width: 20px;
  font-size: 1.1rem;
}

.input-quantity.md svg {
  font-size: 1.2rem;
}

.input-quantity.lg span.quantity {
  width: 50px;
  font-size: 1.5rem;
}

.input-quantity.lg svg {
  font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/helpers/InputQuantity/input-quantity.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;EACpC,WAAW;EACX,kBAAkB;;AAEpB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".input-quantity * {\r\n  line-height: 1;\r\n}\r\n\r\n.input-quantity span.quantity {\r\n  width: 20px;\r\n}\r\n\r\n.input-quantity span.quantity {\r\n  width: 20px;\r\n  font-size: 1rem;\r\n}\r\n\r\n.input-quantity svg {\r\n  font-size: 1.1rem;\r\n}\r\n\r\n.input-quantity input[type=\"number\"] {\r\n  border: 1px solid #eaeaea !important;\r\n  width: 40px;\r\n  text-align: center;\r\n\r\n}\r\n\r\n.input-quantity.md span.quantity {\r\n  width: 20px;\r\n  font-size: 1.1rem;\r\n}\r\n\r\n.input-quantity.md svg {\r\n  font-size: 1.2rem;\r\n}\r\n\r\n.input-quantity.lg span.quantity {\r\n  width: 50px;\r\n  font-size: 1.5rem;\r\n}\r\n\r\n.input-quantity.lg svg {\r\n  font-size: 1.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
