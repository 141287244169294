// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-orders {
  margin: 0 10px;
}

.header-orders .cart {}

.header-orders .cart .badge {}`, "",{"version":3,"sources":["webpack://./src/components/cart/header-cart.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA,sBAAsB;;AAEtB,6BAA6B","sourcesContent":[".header-orders {\r\n  margin: 0 10px;\r\n}\r\n\r\n.header-orders .cart {}\r\n\r\n.header-orders .cart .badge {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
