import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai'; // Importe l'icône souhaitée depuis React Icons
import './backlink.css'
const BackLink = ({ text, to = -1 }) => (

  <Link to={to} className="text-decoration-hover backlink" >
    <AiOutlineArrowLeft size="12" className="me-1" /> <span>{text}</span>
  </Link>

);

export default BackLink;
