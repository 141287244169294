// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userSignInModal {}

.userSignInModal h1 {
  font-size: 1.2rem;
  margin: 0 0 015px;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/user/user-signin-modal.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,iBAAiB;EACjB,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".userSignInModal {}\r\n\r\n.userSignInModal h1 {\r\n  font-size: 1.2rem;\r\n  margin: 0 0 015px;\r\n  padding: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
