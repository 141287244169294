// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs {
  border-bottom: 1px solid #ffde00 !important;
}

.nav-tabs a {
  color: #999;
  -webkit-user-select: none;
          user-select: none;
}

.nav-tabs a:not(.active):hover {
  color: #ffde00 !important;

}


.nav-tabs a.active {
  background-color: #ffe537 !important;
  border-color: #ffde00 !important;

}

.tab-contents {
  border: 1px solid #ffde00;
  border-top: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fafafa;
  padding: 25px;
}`, "",{"version":3,"sources":["webpack://./src/css/tabs.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;AAC7C;;AAEA;EACE,WAAW;EACX,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;;AAE3B;;;AAGA;EACE,oCAAoC;EACpC,gCAAgC;;AAElC;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,+BAA+B;EAC/B,gCAAgC;EAChC,yBAAyB;EACzB,aAAa;AACf","sourcesContent":[".nav-tabs {\r\n  border-bottom: 1px solid #ffde00 !important;\r\n}\r\n\r\n.nav-tabs a {\r\n  color: #999;\r\n  user-select: none;\r\n}\r\n\r\n.nav-tabs a:not(.active):hover {\r\n  color: #ffde00 !important;\r\n\r\n}\r\n\r\n\r\n.nav-tabs a.active {\r\n  background-color: #ffe537 !important;\r\n  border-color: #ffde00 !important;\r\n\r\n}\r\n\r\n.tab-contents {\r\n  border: 1px solid #ffde00;\r\n  border-top: none;\r\n  border-bottom-left-radius: 15px;\r\n  border-bottom-right-radius: 15px;\r\n  background-color: #fafafa;\r\n  padding: 25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
