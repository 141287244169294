// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#adminOrder input[type='date'] {
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
  ;
}`, "",{"version":3,"sources":["webpack://./src/components/orders/admin/admin-orders.css"],"names":[],"mappings":"AAAA;EACE,8EAA8E;;AAEhF","sourcesContent":["#adminOrder input[type='date'] {\r\n  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;\r\n  ;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
