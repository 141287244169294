// utils.js
import API from './api';
import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './css/sweetalert.css';
import { BiErrorAlt } from "react-icons/bi";
export function debug(data, show = 1) {
  if (process.env.NODE_ENV === 'development' && show === 1) {
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  }
  return null;
}
export function isDev() {
  return (process.env.NODE_ENV === 'development')
}
export function formatPrice(price) {
  let formatted = '';
  const numberPrice = typeof price === 'number' ? price : parseFloat(price);
  if (!isNaN(numberPrice)) {
    // if (numberPrice) {
    formatted = numberPrice.toLocaleString('fr-FR', {
      style: 'decimal', // 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    formatted = '----';
  }
  return formatted;
}
export function formatDate(date, format = "d/m/Y") {
  // const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  const dateObj = date instanceof Date ? date : new Date(date);
  const time = dateObj.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit", second: "2-digit" });
  let formatted = `${dateObj.getDate().toString().padStart(2, '0')}/${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getFullYear()}`;
  switch (format) {
    case "d/m/Y H:i":
      formatted = `${dateObj.getDate().toString().padStart(2, '0')}/${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getFullYear()} ${time.substring(0, 5)}`;
      break;
    case "d/m/Y H:i:s":
      formatted = `${dateObj.getDate().toString().padStart(2, '0')}/${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getFullYear()} ${time}`;
      break;
    case "Y-m-d":
      formatted = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;

      break;
    case "textfull": // Nouveau cas pour le format "fulldate"
      formatted = dateObj.toLocaleDateString("fr-FR", options);
      break;
    case "text": // Nouveau cas pour le format "fulldate"
      formatted = dateObj.toLocaleDateString("fr-FR", { weekday: "long", month: "long", day: "numeric" });
      break;
  }
  return formatted;
}

export function formatPhoneNumber(phoneNumber) {

  // Supprimer les espaces et les caractères non numériques
  phoneNumber = phoneNumber.replace(/\D/g, '');



  // Extraire le préfixe (+33)
  const prefixe = phoneNumber.slice(0, 3);

  // Extraire le reste du numéro
  const rest = phoneNumber.slice(3);

  // Formater le reste du numéro (regroupement par 2 chiffres)
  const groups = rest.match(/(\d{2})/g);
  const formattedPhoneNumber = prefixe + ' ' + groups.join(' ');

  return formattedPhoneNumber;


}


export function truncate(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
}
/*
export function download(url, t) {
  // const { t } = useTranslation();
  API.get(url)
    .then((response) => {
      console.log(response.data)
      const byteCharacters = atob(response.data.contents);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.data.mimetype });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.target = '_blank';
      a.textContent = '';
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      Swal.fire({
        grow: false,
        text: i18n.t(error.response.data.error.message),
        showCloseButton: true,
        cancelButtonText: 'Fermer',
        showCancelButton: true,
        showConfirmButton: false,
        // iconHtml: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4l-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z"></path></svg>',
        customClass: {
          cancelButton: 'btn-sm btn-green'
        }
      });
    });
}*/

export function download(url) { // téléchargement direct sans ouverture de fenêtre
  API.handleError = false;
  API.get(url)
    .then((response) => {
      const byteCharacters = atob(response.data.contents);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.data.mimetype });
      const blobUrl = window.URL.createObjectURL(blob);

      // Création d'un lien de téléchargement
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = response.data.filename || 'telechargement.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Libération de l'URL de l'objet après usage
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      Swal.fire({
        grow: false,
        text: i18n.t(error.response?.data?.error?.message || 'Une erreur est survenue.'),
        showCloseButton: true,
        cancelButtonText: 'Fermer',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          cancelButton: 'btn-sm btn-green'
        }
      });
    });
}
export async function file(path) {
  try {
    const response = await API.get('/utils/file?path=' + path);
    const byteCharacters = atob(response.data.contents);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: response.data.mimetype });
    const blobUrl = window.URL.createObjectURL(blob); // Créer une URL à partir du blob
    return blobUrl; // Retourner l'URL du blob
  }
  catch (error) {
    Swal.fire({
      grow: false,
      text: i18n.t(error.response.data.error.message),
      showCloseButton: true,
      cancelButtonText: 'Fermer',
      showCancelButton: true,
      showConfirmButton: false,
      // iconHtml: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4l-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z"></path></svg>',
      customClass: {
        cancelButton: 'btn-sm btn-green'
      }
    });
  };
}
export function swalAlert(message, status) {
  Swal.fire({
    grow: false,
    html: message,
    showCloseButton: true,
    cancelButtonText: 'Fermer',
    showCancelButton: true,
    showConfirmButton: false,
    customClass: {
      cancelButton: 'btn-sm btn-green'
    }
  });
}
export function swalConfirm(message, buttons = {}, status) {
  return Swal.fire({
    grow: false,
    html: message,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: buttons.confirmText || 'Confirmer',
    cancelButtonText: buttons.cancelText || 'Annuler',
    showConfirmButton: true,
    customClass: {
      cancelButton: 'btn-sm btn-red',
      confirmButton: 'btn-sm btn-green'
    },
    // icon: status || 'warning'
  }).then((result) => {
    if (result.isConfirmed) {
      return true; // L'utilisateur a cliqué sur le bouton de confirmation
    } else {
      return false; // L'utilisateur a annulé ou fermé la boîte de dialogue
    }
  });
}
export async function swalInputBox(type, message, placeholder = '', status) {
  const { value: textValue } = await Swal.fire({
    input: type,
    inputValue: message,
    inputPlaceholder: placeholder,
    inputAttributes: {
      'aria-label': placeholder,
      'class': ''
    },
    showCancelButton: true,
    confirmButtonText: 'Valider',
    cancelButtonText: 'Annuler',
    showLoaderOnConfirm: true,
    preConfirm: (text) => {
      return text;
    },
    customClass: {
      container: '',
      popup: '',
      header: '',
      title: '',
      closeButton: 'btn btn-sm btn-default',
      icon: '',
      image: '',
      content: '',
      input: 'form-control form-control-sm mb-2 mx-0',
      actions: '',
      closeButton: 'btn btn-sm btn-default',
      confirmButton: 'btn btn-sm btn-outline-warning',
      cancelButton: 'btn btn-sm btn-default',
      footer: ''
    },
    allowOutsideClick: () => !Swal.isLoading()
  });
  return textValue;
}
export function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
export function ucFirst(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
export function addBusinessDays(startDate, numberOfDays) {
  let date = new Date(startDate);
  const step = numberOfDays >= 0 ? 1 : -1;
  numberOfDays = Math.abs(numberOfDays)
  while (numberOfDays > 0) {
    date = new Date(date.setDate(date.getDate() + step));
    if (date.getDay() !== 0 && date.getDay() !== 6) { // Exclut les week-ends
      numberOfDays--;
    }
  }
  return date;
}
