// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.standalone header {
  padding: 0 25px;
  position: fixed;
  width: 100%;
  border-bottom: 2px solid #ffde00;
  background-color: white;
  z-index: 1000;
}


body.standalone header .col {
  padding: 10px;
  display: flex;
  align-items: center;

}



body.standalone header a {
  color: #333;
  text-decoration: none;
  display: inline-block;
  padding: 0 10px;
}



body.standalone header .logo img {
  max-height: 70px;
  transition: all;
}


body.standalone body.scrolled header .logo img {
  max-height: 40px;
  transition: all 700ms;
}

header .badge-wrapper {
  position: relative;

}

header .badge-wrapper .badge {
  position: absolute;
  top: 60%;
  left: 60%;
  padding: 2px 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/header.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,WAAW;EACX,gCAAgC;EAChC,uBAAuB;EACvB,aAAa;AACf;;;AAGA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;;AAErB;;;;AAIA;EACE,WAAW;EACX,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;AACjB;;;;AAIA;EACE,gBAAgB;EAChB,eAAe;AACjB;;;AAGA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;;AAEpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gBAAgB;AAClB","sourcesContent":["body.standalone header {\r\n  padding: 0 25px;\r\n  position: fixed;\r\n  width: 100%;\r\n  border-bottom: 2px solid #ffde00;\r\n  background-color: white;\r\n  z-index: 1000;\r\n}\r\n\r\n\r\nbody.standalone header .col {\r\n  padding: 10px;\r\n  display: flex;\r\n  align-items: center;\r\n\r\n}\r\n\r\n\r\n\r\nbody.standalone header a {\r\n  color: #333;\r\n  text-decoration: none;\r\n  display: inline-block;\r\n  padding: 0 10px;\r\n}\r\n\r\n\r\n\r\nbody.standalone header .logo img {\r\n  max-height: 70px;\r\n  transition: all;\r\n}\r\n\r\n\r\nbody.standalone body.scrolled header .logo img {\r\n  max-height: 40px;\r\n  transition: all 700ms;\r\n}\r\n\r\nheader .badge-wrapper {\r\n  position: relative;\r\n\r\n}\r\n\r\nheader .badge-wrapper .badge {\r\n  position: absolute;\r\n  top: 60%;\r\n  left: 60%;\r\n  padding: 2px 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
